export const USPS_RETURNS = "usps_returns";
export const FEDEX = "fedex";
export const BLUEDART = "bluedart";
export const UPS = "ups";
export const CANADA_POST = "canadapost";
export const DHL_EXPRESS = "dhlexpress";
export const DHL_PAKET = "dhlpaket";
export const DPD_UK = "dpduk";
export const AUS_POST = "auspost";
export const CYCLEON = "cycleon";
export const SWISS_POST = "swisspost";
export const ROYAL_MAIL = "royal_mail";
export const COLLECT_PLUS = "collectplus";
export const CORREOS_EXPRESS = "correosexpress";
export const COLISSIMO = "colissimo";
export const EVRI = "evri";
export const XPO = "xpo";
export const PITNEY_BOWES = "pitneybowes";
export const CHRONOPOST = "chronopost";
export const SHIP_ENGINE = "ship_engine";
export const POSTNORD = "postnord";
export const GLS = "gls";
export const GLSDK = "gls-dk";
export const GLSFR = "gls-fr";
export const GLSCA = "glsca";
export const DHL_GLOBAL = "dhlglobal";
export const YAMATO = "yamato";
export const DELIVERR = "deliverr";
export const INPOSTUK = "inpostuk";
export const DODDLE = "doddle";
export const HERMES = "hermes";
export const FEDEX_COMMERCIAL = "fedex-commercial";
export const APG = "apg";
export const MONDIAL_RELAY = "mondial-relay";
export const MAERSK_EU = "maersk-eu";
export const DHLPBENELUX = "dhlpbenelux";
export const BLECKMANN = "bleckmann";
export const SHOPLINE = "shopline";
export const PUROLATOR = "purolator";
export const BPOST = "bpost";
export const POSTNL = "postnl";

// Carriers that support only boxless returns, but not paperless returns.
// Frontend should treat paperless as boxless for these carriers.
export const BOXLESS_ONLY_CARRIERS = [DODDLE, FEDEX_COMMERCIAL];

export const carrierNames = {
  [USPS_RETURNS]: "USPS Returns",
  [FEDEX]: "FedEx",
  [BLUEDART]: "BlueDart",
  [UPS]: "UPS",
  [DHL_PAKET]: "DHL Paket",
  [DHL_EXPRESS]: "DHL Express",
  [CANADA_POST]: "Canada Post",
  [DPD_UK]: "DPK UK",
  [AUS_POST]: "Australia Post",
  [CYCLEON]: "Cycleon",
  [SWISS_POST]: "Swiss Post",
  [ROYAL_MAIL]: "Royal Mail",
  [COLLECT_PLUS]: "Collect Plus",
  [CORREOS_EXPRESS]: "Correos Express",
  [COLISSIMO]: "Colissimo",
  [EVRI]: "Evri",
  [XPO]: "Xpo",
  [PITNEY_BOWES]: "Pitney Bowes",
  [CHRONOPOST]: "Chronopost",
  [SHIP_ENGINE]: "Ship Engine",
  [POSTNORD]: "Post Nord",
  [GLS]: "GLS",
};

export const incoterms = [
  { name: "EXW", description: "Ex Works" },
  { name: "FCA", description: "Free Carrier" },
  { name: "FAS", description: "Free Alongside Ship" },
  { name: "FOB", description: "Free on Board" },
  { name: "CPT", description: "Carriage Paid" },
  { name: "CIP", description: "Carriage and Insurance Paid" },
  { name: "CFR", description: "Cost and Freight" },
  { name: "CIF", description: "Cost, Insurance and Freight" },
  { name: "DAP", description: "Delivered at Place" },
  { name: "DPU", description: "Delivered at Place Unloaded" },
  { name: "DDP", description: "Delivered Duty Paid" },
  { name: "DDU", description: "Delivered Duty Unpaid" },
];

export const DHL_EXPESS_SERVICE_TYPES = {};

export const USPS_SERVICE_TYPES = {
  PARCEL_RETURN_SERVICE: "Parcel Return Service",
  PRS_FULL_NETWORK: "PRS - Full Network",
  GROUND_RETURN_SERVICE: "Ground Return Service",
  PRS_FULL_NETWORK_INSURANCE_LT_200: "PRS - Full Network, Insurance <=200",
  PRS_FULL_NETWORK_INSURANCE_GT_200: "PRS - Full Network, Insurance > 200",
  PRIORITY_MAIL_RETURNS_SERVICE: "Priority Mail Returns service",
  PRIORITY_MAIL_RETURNS_SERVICE_INSURANCE_LT_200:
    "Priority Mail Returns service, Insurance <= $200",
  FIRST_CLASS_PACKAGE_RETURN_SERVICE: "First-Class Package Return service",
  FIRST_CLASS_PACKAGE_RETURN_SERVICE_INSURANCE_LT_200:
    "First-Class Package Return service, Insurance <= $200",
};
