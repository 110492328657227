import React, { useState, useEffect, useMemo } from "react";
import { get, sumBy, isEmpty, merge, isPlainObject, includes } from "lodash";
import { isNil, last } from "lodash/fp";

import { Grid, Typography } from "@material-ui/core";
import { ReviewSummaryRaw } from "@narvar/nth-kit-returns-headless";

import { makeRootStyles } from "../../theme/styles";
import {
  METHOD_KEEP_THE_ITEM,
  RETURN_CREDIT_METHOD_REFUND,
  RETURN_CREDIT_METHOD_RESHOP,
} from "../../../retailer-app/constants/returns";
import Spinner from "../../../shared/components/Spinner";
import { config } from "../../config";
import {
  CONFIRMATION_STEP,
  REVIEW_STEP,
  useReturnStepIsActive,
  useReturnStepsActions,
  useReturnStepsState,
} from "../../contexts/returnSteps";
import { useSubmitReturn } from "../../data/submitReturn";
import { useCalculateRefund } from "../../data/calculateRefund";
import { createCarouselItemKey, buildItemsByType } from "../../modules/items";
import locale from "../../../shared/modules/lang";
import { redirectToCheckout } from "../../../shared/modules/frame";
import MailingAddressForm from "../ConfirmMailingAddress/MailingAddressForm";
import Details from "./Details";
import Item from "./Item";
import Refund from "./Refund";
import CheckoutForm, { confirmCardPayment } from "./CheckoutForm";
import { Submit } from "./Submit";
import { isValidHttpUrl } from "../../../shared/modules/string";
import { mergeAddress } from "../../modules/address";
import {
  ignoreEmpty,
  omitDeep,
  useMergeRequestStatus,
} from "../../../shared/modules/object";
import { isLocalStorageEnabled } from "../../../shared/modules/localStorage";
import {
  getAddressError,
  getPaymentNewClientSecret,
  getReshopError,
} from "../../../shared/modules/decodeError";
import { calculateReturnMetrics, useEmitMetrics } from "../../data/emitMetric";
import {
  convertFromDatadogMetrics,
  countingEvent,
  SAVE_PREFERENCES_CHECKED_EVENT,
  SUBMIT_RETURN_SUCCESS_EVENT,
  useAbTesting,
} from "../../data/abTesting";
import { useTypeForm } from "../../contexts/typeForm";
import { useNthReturnsActions } from "../../contexts/nthContext";
import { useStripeComponents } from "../../contexts/StripeProvider";
import { getTranslation } from "../../../shared/modules/config";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import errorNotifier from "../../../shared/modules/error-notifier";
import {
  isPreferredMethod,
  toReturnMethodPreference,
  useConsumerPreferences,
} from "../../data/consumerPreferences";
import titan from "../../../shared/modules/titan";
import useConsumerSettings from "../../hooks/useConsumerSettings";
import { usePreApproval } from "../../data/preApproval";
import { useUuid } from "../../../shared/modules/localId";

const {
  isStripeEnabled,
  isRefundAutomationEnabled,
  isPaymentFlowEnabled,
} = config;

const useStyles = makeRootStyles(
  theme => ({
    root: {
      marginTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    submitContainer: {
      marginTop: theme.spacing(4),
    },
    submitButton: {
      marginBottom: theme.spacing(2),
    },
    loadingContainer: {
      marginBottom: theme.spacing(8),
    },
    returnItemsSectionHeader: {
      backgroundColor: theme.palette.action.selected,
      padding: theme.spacing(1),
    },
  }),
  { name: "N0Review" },
);

const Review = ({ orderFetchData, draftReturnUuid }) => {
  const classes = useStyles();
  const actions = useReturnStepsActions();
  const state = useReturnStepsState();
  const { submitReturn, submitStatus } = useSubmitReturn();
  const { emitMetrics } = useEmitMetrics();
  const abTesting = useAbTesting();

  const { order, returnReasonsById = {} } = orderFetchData;
  const { itemsById } = order;

  const { exchangeItems, returnItems } = useMemo(
    () => buildItemsByType(state.items, itemsById, returnReasonsById),
    [itemsById, returnReasonsById, state.items],
  );

  const [isErrorSnackbarVisible, setErrorSnackbarVisibility] = useState(false);
  const [capturingPayment, setCapturingPayment] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    config.translations.return_review_submit_error,
  );
  const [succeededPaymentIntentId, setSucceededPaymentIntentId] = useState();
  const selectedReturnMethod = state.selectedReturnMethod;
  const keepTheItemMethod = selectedReturnMethod.id === METHOD_KEEP_THE_ITEM;
  const chosenRefundMethod = state?.selectedReturnCreditMethod?.id;

  const calculateRefundPayload = {
    exchangeItems: exchangeItems.map(item => {
      return {
        ...item,
        returnItem: {
          ...item.returnItem,
          pictures: undefined,
        },
      };
    }),
    returnItems: returnItems.map(item => {
      return {
        ...item,
        pictures: undefined,
      };
    }),
    shopifyOrderId: order.shopifyOrderId,
    draftReturnUuid,
    presentmentCurrency: order.presentmentCurrency,
    shippingPrice: selectedReturnMethod.price,
    shippingMethod: selectedReturnMethod.id,
    carrierServiceName: selectedReturnMethod.carrierService,
    orderNumber: state.orderNumber,
    email: state.email,
    locale,
    chosenRefundMethod,
    fromAddress: ignoreEmpty(state.fromAddress),
    csid: state.csid,
  };
  const { refund, queryStatus: reviewStatus, refetch } = useCalculateRefund(
    calculateRefundPayload,
  );

  const uuid = useUuid();
  const preApprovalReturnUuid =
    config.isReshopEarlyHardApproval &&
    (chosenRefundMethod || refund.chosenRefundMethod) ===
      RETURN_CREDIT_METHOD_RESHOP
      ? uuid
      : undefined;
  const preApprovalHook = usePreApproval({
    ...calculateRefundPayload,
    preApprovalReturnUuid,
    refundCalculated: {
      currency: order.presentmentCurrency,
      cents: state?.selectedReturnCreditMethod?.newPriceCents,
    },
  });

  const queryStatus = useMergeRequestStatus(
    reviewStatus,
    preApprovalHook.status,
  );

  useEffect(() => {
    if (!queryStatus.loading && preApprovalHook.status.error) {
      setErrorMessage(preApprovalHook.status.error.message);
      setErrorSnackbarVisibility(true);

      const available = state.availableReturnCreditMethods.filter(
        v => v.id !== chosenRefundMethod,
      );
      const fallback =
        available.find(v => v.id === RETURN_CREDIT_METHOD_REFUND) ??
        last(available);
      actions.setFallbackReturnCreditMethod(fallback);
    }
  }, [queryStatus.loading, preApprovalHook.status.error]);

  const refundMethods = refund.refundMethodsAvailable;

  // In case a of failure during backend processing of payment, the backend issues a new payment intent
  // to allow the consumer to restart the process.
  const [
    currentPaymentIntentClientSecret,
    setCurrentPaymentIntentClientSecret,
  ] = useState();
  useEffect(() => {
    setCurrentPaymentIntentClientSecret(refund.paymentClientSecret);
  }, [refund]);
  const setNewPaymentClientSecretFromError = status => {
    const newClientSecret = getPaymentNewClientSecret(status?.errorDetail);
    if (newClientSecret) {
      setCurrentPaymentIntentClientSecret(newClientSecret);
      setSucceededPaymentIntentId(null);
    }
  };

  const loadingOrCheckoutRedirect =
    submitStatus.loading ||
    (isPaymentFlowEnabled &&
      submitStatus.data &&
      isValidHttpUrl(submitStatus.data));

  useEffect(() => {
    if (submitStatus.error) {
      setNewPaymentClientSecretFromError(submitStatus);
      const isReshopError = !!getReshopError(submitStatus.errorDetail);
      setErrorMessage(
        isReshopError
          ? config.translations.return_review_submit_error_reshop
          : get(
              submitStatus,
              "error",
              config.translations.return_review_submit_error,
            ),
      );
      return setErrorSnackbarVisibility(true);
    } else {
      setErrorMessage();
      setErrorSnackbarVisibility(false);
    }
    if (submitStatus.loading) return;
    if (!submitStatus.data) return;

    if (isPaymentFlowEnabled) {
      const checkoutUrl = submitStatus.data;
      if (isValidHttpUrl(checkoutUrl)) {
        if (isLocalStorageEnabled) {
          console.warn(
            "nv_shop_now_checkout",
            window.localStorage.getItem("nv_shop_now_checkout"),
          );
          if (!window.localStorage.getItem("nv_shop_now_checkout")) {
            console.warn("setting", checkoutUrl);
            window.localStorage.setItem("nv_shop_now_checkout", checkoutUrl);
            console.warn(
              "set",
              window.localStorage.getItem("nv_shop_now_checkout"),
            );
          }
        }
        return redirectToCheckout(checkoutUrl);
      }
    }

    actions.setSubmitSuccess({
      submitResult: submitStatus.data,
      refundTotal: refund.totalRefundCents,
    });
    actions.goToStep(CONFIRMATION_STEP);
  }, [actions, submitStatus]);

  const ItemsToReceive = ({ state, exchangeItems = [] }) => {
    return (
      <React.Fragment>
        {state.items && state.items.length && exchangeItems.length > 0 && (
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            className={classes.returnItemsSectionHeader}>
            {config.translations.return_review_receiving_items}
          </Typography>
        )}
        {state.items
          .filter(
            item =>
              item.returnReason &&
              returnReasonsById[item.returnReason] &&
              item.type === "exchange",
          )
          .map(item => {
            const details = itemsById[item.id];

            const keepTheItem =
              !keepTheItemMethod && state.keepItemIds.includes(item.id);

            return (
              <Item
                key={createCarouselItemKey(item)}
                details={details}
                keepTheItem={keepTheItem}
                state={item}
                isOriginal={false}
              />
            );
          })}
      </React.Fragment>
    );
  };

  const ItemsForReturn = ({ state, exchangeItems = [] }) => {
    return (
      <React.Fragment>
        {state.items && state.items.length && (
          <Typography
            variant="h3"
            align="center"
            gutterBottom
            className={classes.returnItemsSectionHeader}>
            {config.translations.return_review_returning_items}
          </Typography>
        )}
        {state.items
          .filter(
            item => item.returnReason && returnReasonsById[item.returnReason],
          )
          .map(item => {
            const details = itemsById[item.id];
            const keepTheItem =
              !keepTheItemMethod && state.keepItemIds.includes(item.id);

            // nested::todo frontend should send untranslated reason ids
            const returnReason = returnReasonsById[item.returnReason];
            const childReturnReasonId = get(
              item,
              "childReturnReason.returnReason",
            );
            const childReturnReason = returnReasonsById[childReturnReasonId];
            const fullReturnReason = childReturnReason
              ? `${returnReason.reasonTitle}, ${returnReasonsById[childReturnReasonId].reasonTitle}`
              : returnReason.reasonTitle;

            return (
              <Item
                key={createCarouselItemKey(item)}
                details={details}
                keepTheItem={keepTheItem}
                returnReasonTitle={fullReturnReason}
                state={item}
              />
            );
          })}
      </React.Fragment>
    );
  };

  const fromAddress = ignoreEmpty(state.fromAddress);
  const submitProps = overrides =>
    merge(
      {
        locale,
        exchangeItems,
        returnItems,
        orderNumber: state.orderNumber,
        email: state.email,
        location: omitDeep(["__typename"], state.location),
        locationId: state.locationId,
        shopifyOrderId: order.shopifyOrderId,
        presentmentCurrency: order.presentmentCurrency,
        shippingPrice: selectedReturnMethod.price,
        shippingMethod: selectedReturnMethod.id,
        carrierServiceName: selectedReturnMethod.carrierService,
        selectedRefundMethod: isRefundAutomationEnabled
          ? chosenRefundMethod || refund.chosenRefundMethod
          : undefined,
        customerPmtId:
          isStripeEnabled && isCharge ? refund.customerPmtId : undefined,
        paymentMethodId: undefined,
        fromAddress,
        csid: state.csid,
        homePickupInstructions: state.homePickupInstructions,
        preApprovalReturnUuid,
        preApprovalId: preApprovalHook.state?.preApprovalId,
        requestedLabelCount: state.requestedLabelCount,
        reshopOptions: showReshopSmsUpdates ? state.reshopOptions : undefined,
      },
      overrides,
    );

  const handleSubmit = overrides => {
    if (overrides?.paymentId) setSucceededPaymentIntentId(overrides.paymentId);
    setErrorSnackbarVisibility(false);
    const paymentMethodId =
      isStripeEnabled && isCharge
        ? { paymentMethodId: overrides?.paymentId || succeededPaymentIntentId }
        : {};

    const locallyCorrectedFromAddress = ignoreEmpty(overrides?.fromAddress);
    if (locallyCorrectedFromAddress) {
      actions.setCorrectedAddress(locallyCorrectedFromAddress);
    }

    const globallyCorrectedFromAddress = ignoreEmpty(state?.fromAddress);

    // Enable navigation prompt: "Are you sure you want to navigate away from this page?"
    window.onbeforeunload = () => {
      return true;
    };

    const validResultCodes = [
      "ok", // either a label was generated (98% of returns) or no label was needed
      "label_retry_enqueued", // a carrier outage was detected, and a LabelJob has been enqueued
    ];

    const verifyAndSetResultCode = function(r) {
      if (!isPlainObject(r)) {
        throw "Invalid response body from SubmitReturn: expected a plain object";
      }
      const resultCode = get(r, "data.submitReturn");
      if (includes(validResultCodes, resultCode)) {
        actions.setSubmitReturnResultCode(resultCode);
      } else if (resultCode.startsWith("http")) {
        // It is a payment-flow or shop-now URL.
        actions.setSubmitReturnResultCode(null);
      } else {
        throw "Invalid result code: " + resultCode;
      }
    };

    const variables = submitProps({
      ...paymentMethodId,
      draftReturnUuid,
      fromAddress: locallyCorrectedFromAddress || globallyCorrectedFromAddress,
    });
    submitReturn(variables)
      .then(r => {
        const metrics = calculateReturnMetrics(
          order,
          variables.selectedRefundMethod,
          state.items,
          state.lineItemIntension,
          refundMethods?.length === 1 &&
            variables.selectedRefundMethod === "original_payment",
        );
        emitMetrics(metrics);
        if (config.isNthUi) {
          // success event should submit first, so other revenue metrics can use
          // this as a denominator in growthbook
          abTesting.trackResult(countingEvent(SUBMIT_RETURN_SUCCESS_EVENT));
          const abTestResult = convertFromDatadogMetrics(metrics);
          abTesting.trackResult(abTestResult);
        }

        if (config.isUserPreferencesEnabled) {
          abTesting.trackResult(
            countingEvent(
              SAVE_PREFERENCES_CHECKED_EVENT,
              state.savePreferences ? 1 : 0,
            ),
          );

          if (preferencesStatus?.profileStatus === "active") {
            const method = state.selectedReturnMethod;
            titan.track("product_added", {
              props: {
                list_id: "pudo_options",
                product_id: toReturnMethodPreference(method),
                variant: isPreferredMethod(method, consumerPreferences)
                  ? "PREFERRED"
                  : null,
              },
            });
          }
        }

        verifyAndSetResultCode(r);
      })
      .finally(() => (window.onbeforeunload = null));
  };

  const isTotalNegative = refund.totalRefundCents < 0;
  const paymentIdPresent = !!succeededPaymentIntentId;

  const isPreCharge = !!refund.preChargeFeeAmountCents;
  const isCharge =
    (isStripeEnabled || isPaymentFlowEnabled) &&
    (isTotalNegative || isPreCharge);
  const renderCheckout =
    isStripeEnabled && isCharge && !succeededPaymentIntentId;

  const totalRefundPositive = isTotalNegative
    ? refund.totalRefund.replace("-", "")
    : refund.totalRefund;

  const titleTranslation = (() => {
    if (returnItems.length && exchangeItems.length) {
      return config.translations.return_review_title_return_and_exchange;
    }
    if (!returnItems.length && exchangeItems.length) {
      return config.translations.return_review_title_exchange;
    }
    return config.translations.return_review_title;
  })();

  const Loading = () => (
    <Grid className={classes.loadingContainer} spacing={2} container>
      <Grid xs={12} item>
        <Spinner />
      </Grid>
    </Grid>
  );

  const returnItemCount = sumBy(state.items, "quantity"); // all return and exchange items count as returning
  const receiveItemCount = sumBy(exchangeItems, "returnItem.quantity");

  const OrderDetails = () => (
    <>
      <Details
        refundTotal={totalRefundPositive}
        returnItemCount={returnItemCount}
        receiveItemCount={receiveItemCount}
        returnMethodDisplayName={
          !keepTheItemMethod && selectedReturnMethod.displayName
        }
        isCharge={isCharge}
        isPreCharge={isPreCharge}
      />
      <div>
        <ItemsForReturn
          state={state}
          exchangeItems={exchangeItems}></ItemsForReturn>
        <ItemsToReceive
          state={state}
          exchangeItems={exchangeItems}></ItemsToReceive>
        <Refund
          taxesIncluded={refund.taxesIncluded}
          totalDisplayPrice={totalRefundPositive}
          totalRefundCents={refund.totalRefundCents}
          totalDisplayTax={refund.totalTaxDisplayAmt}
          priorRefundsDisplayAmt={refund.priorRefundsDisplayAmt}
          subtotalDisplayPrice={refund.subtotalRefund}
          purchaseShippingDisplayPrice={
            refund.originalShippingRefundedDisplayAmt
          }
          returnMethodDisplayName={
            keepTheItemMethod ? (
              <Typography color="primary" component="span" variant="body1">
                <strong>
                  {config.translations.return_review_keep_item_disclaimer}
                </strong>
              </Typography>
            ) : (
              selectedReturnMethod.displayName
            )
          }
          returnMethodDisplayPrice={refund.returnMethodDisplayPrice}
          exchangeShippingFeeDisplayPrice={
            refund.exchangeShippingFeeDisplayPrice
          }
          exchangeShippingFeeDisplayName={refund.exchangeShippingFeeDisplayName}
          isCharge={isCharge}
          isPreCharge={isPreCharge}
          feeBreakdown={refund.feeBreakdown}
          refundMethod={state.selectedReturnCreditMethod.description}
        />
        {renderCheckout && !state.submitted && (
          <CheckoutForm
            secret={currentPaymentIntentClientSecret}
            onSuccess={handleSubmit}
            submitLoading={submitStatus.loading}
            backendPaymentFailureError={
              isErrorSnackbarVisible ? errorMessage : null
            }
          />
        )}
        {!renderCheckout && (
          <Submit
            {...{
              submitStatus,
              state,
              errorMessage,
              isErrorSnackbarVisible:
                !submitStatus.loading &&
                !state.submitted &&
                isErrorSnackbarVisible,
              isCharge,
              showContactRetailer: !isPaymentFlowEnabled,
              handleSubmit,
              paymentIdPresent,
            }}
            loading={loadingOrCheckoutRedirect}
          />
        )}
      </div>
    </>
  );

  const handleCalculateRefundAddressFix = ({ fromAddress }) => {
    actions.setCorrectedAddress(fromAddress);
    refetch({
      ...calculateRefundPayload,
      fromAddress,
    });
  };

  /***************************************************************************************************************
   * New design with `nth` components
   ***************************************************************************************************************/
  const useNewDesign = config.isNthUi;
  const typeForm = useTypeForm();
  const activeStep = useReturnStepIsActive(REVIEW_STEP);
  const nthReturnsActions = useNthReturnsActions();
  const { localeFull, includeCurrency } = useConsumerSettings();
  const { stripe, elements: stripeElement } = useStripeComponents();
  const { preferencesStatus, consumerPreferences } = useConsumerPreferences(
    state.guestReturn ? "" : state.email,
  );
  const nthFees = useMemo(() => {
    if (!isEmpty(refund)) {
      const currency = refund.currency;
      const ret = [];

      ret.push({
        title: refund.taxesIncluded
          ? config.translations.return_review_taxes_included
          : config.translations.return_review_total_tax,
        type: "TAX",
        displayAmount: refund.totalTaxDisplayAmt,
      });

      if (refund.priorRefundsDisplayAmt) {
        ret.push({
          title: config.translations.return_review_prior_refunds,
          type: "OTHERS",
          displayAmount: refund.priorRefundsDisplayAmt,
        });
      }

      if (refund.originalShippingRefundedDisplayAmt) {
        ret.push({
          title: config.translations.return_review_original_purchase_shipping,
          type: "OTHERS",
          displayAmount: refund.originalShippingRefundedDisplayAmt,
        });
      }

      (refund.feeBreakdown ?? [])
        .filter(
          i =>
            !config.shouldHideNarvarSeamlessExchangeValue ||
            (i.description != "NARVAR_SEAMLESS_EXCHANGE_VALUE" &&
              i.description != "NARVAR_SEAMLESS_EXCHANGE_VALUE_TAX"),
        )
        .forEach(fee => {
          ret.push({
            title: fee.description,
            type: "LABEL_FEE",
            displayAmount: fee.formattedFee,
          });
        });

      if (refund.exchangeShippingFeeDisplayName) {
        const sign =
          isCharge &&
          parseFloat(refund.exchangeShippingFeeDisplayPrice.substring(1)) !== 0
            ? "-"
            : "";
        ret.push({
          title: refund.exchangeShippingFeeDisplayName,
          type: "OTHERS",
          displayAmount: sign + refund.exchangeShippingFeeDisplayPrice,
        });
      }

      return ret;
    }
  }, [refund]);
  const nthRefund = useMemo(() => {
    if (!isEmpty(refund)) {
      const currency = refund.currency;
      const total = refund.totalRefundCents;
      const subtotal =
        config.shouldHideNarvarSeamlessExchangeValue && total == 0
          ? 0
          : refund.subtotalRefundCents;
      const ret = {
        displayAllFees: true,
        total: { value: total, currency },
        subtotal: { value: subtotal, currency },
        refundMethod: nthReturnsActions.returnCart?.refundMethod,
      };
      return ret;
    }
  }, [refund, isCharge, nthReturnsActions.returnCart?.refundMethod]);
  const handleReviewSummaryChange = event => {
    // we won't have email / policy onChange event

    // update save preferences
    if (!isNil(event.value?.savePreferences)) {
      actions.setSavePreferences(event.value.savePreferences);
    }

    if (!isNil(event.value?.reshopOptions)) {
      setReshopOptionsError(); // clear error message
      actions.setReshopOptions({
        smsUpdates: event.value.reshopOptions.subscribe,
        customerPhone: event.value.reshopOptions.phone,
      });
    }
  };
  useEffect(() => {
    if (config.isUserPreferencesEnabled && !preferencesStatus?.profileStatus) {
      titan.track("promotion_viewed", {
        props: { promotion_id: "user_preferences" },
      });
    }
  }, [preferencesStatus?.profileStatus]);

  const [reshopOptionsError, setReshopOptionsError] = useState();
  const showReshopSmsUpdates =
    config.isReshopSmsUpdates &&
    state?.selectedReturnCreditMethod?.id === RETURN_CREDIT_METHOD_RESHOP;
  const reshopOptions = useMemo(
    () =>
      showReshopSmsUpdates
        ? {
            subscribe: !!state.reshopOptions.smsUpdates,
            phone: state.reshopOptions.customerPhone ?? "",
            error: reshopOptionsError,
          }
        : undefined,
    [showReshopSmsUpdates, state.reshopOptions, reshopOptionsError],
  );

  const submittingAddressError = getAddressError(submitStatus?.errorDetail);
  useEffect(() => {
    if (
      useNewDesign &&
      isErrorSnackbarVisible &&
      errorMessage &&
      !submittingAddressError
    ) {
      typeForm.scrollToActive();
    }
  }, [isErrorSnackbarVisible, errorMessage]);
  const [isNewAddressValid, setIsNewAddressValid] = useState(false);
  const [newAddressDraft, setNewAddressDraft] = useState({});

  // calculateRefund could return an `address` error and the frontend
  // will allow the user to provide an address and execute calculateRefund
  // again via `refetch`.
  const addressError = reviewStatus.addressError;
  if (addressError) {
    return (
      <Grid
        className={classes.submitContainer}
        container
        justifyContent="center"
        alignItems="center">
        <Typography align="center" className={classes.title} variant="h1">
          {config.translations.mailing_address_section_title}
        </Typography>
        <MailingAddressForm
          address={mergeAddress(state.fromAddress, addressError.address)} // preserve user input address, but override with server suggested fields
          onSubmit={handleCalculateRefundAddressFix}
          disable={addressError.disableFields}
          validateZip={true}
        />
      </Grid>
    );
  }

  if (useNewDesign) {
    if (state.submitted) return null;

    const secret = currentPaymentIntentClientSecret;
    const submitting = capturingPayment || loadingOrCheckoutRedirect;
    const hasAddressError = submittingAddressError;
    const hasOtherError =
      isErrorSnackbarVisible && errorMessage && !hasAddressError;
    const stripeAddOn = renderCheckout && !state.submitted && (
      <CheckoutForm
        secret={secret}
        onSuccess={handleSubmit}
        hideSubmit
        submitLoading={submitting}
        backendPaymentFailureError={hasOtherError ? errorMessage : null}
      />
    );
    const addressCorrectionAddOn = hasAddressError && (
      <>
        <ErrorSnackbar message={errorMessage} />
        <MailingAddressForm
          address={mergeAddress(
            state.fromAddress,
            submittingAddressError.address,
            newAddressDraft,
          )}
          onChange={event => {
            const { fromAddress, isValid } = event;
            setIsNewAddressValid(isValid);
            setNewAddressDraft(fromAddress);
          }}
          onSubmit={({ fromAddress }) => {
            errorNotifier.warn(
              "Submit button of <MailingAddressFrom/> should be hidden, but we received onSubmit event",
              { fromAddress },
            );
          }}
          disable={submittingAddressError.disableFields}
          submitLabel={getTranslation("bad_mailing_address_submit")}
          validateZip={true}
          exclude={["phone", "submit"]}
        />
      </>
    );
    const reminderText =
      exchangeItems.length > 0
        ? getTranslation("return_review_reminder_text_exchange")
        : getTranslation("return_review_reminder_text_return");
    const reminderAddOn = reminderText && (
      <Typography>{reminderText}</Typography>
    );
    const validateReshopOptions = () => {
      if (state.reshopOptions.smsUpdates) {
        const phone = state.reshopOptions.customerPhone;
        if (!phone) {
          setReshopOptionsError(
            getTranslation(
              "return_review_reshop_options_error_customer_phone_required",
            ),
          );
          return false;
        }
        if (!/^\d{10}$/.test(phone)) {
          setReshopOptionsError(
            getTranslation(
              "return_review_reshop_options_error_invalid_customer_phone",
            ),
          );
          return false;
        }
      }
      setReshopOptionsError();
      return true;
    };
    const newOnSubmitHandler = !renderCheckout
      ? () =>
          validateReshopOptions() &&
          handleSubmit({ fromAddress: newAddressDraft })
      : async () => {
          setCapturingPayment(true);
          setErrorMessage();

          if (!validateReshopOptions()) return;

          return confirmCardPayment({ stripe, elements: stripeElement, secret })
            .then(result => {
              setCapturingPayment(false);
              return handleSubmit({ fromAddress: newAddressDraft, ...result });
            })
            .catch(error => {
              setCapturingPayment(false);
              setErrorMessage(error.message ?? error);
            });
        };

    return (
      <Grid className={classes.root} spacing={2} container>
        <Grid xs={12} item>
          <ReviewSummaryRaw
            locale={localeFull}
            includeCurrency={includeCurrency}
            progressiveStep
            activeStep={activeStep}
            error={hasOtherError ? <div>{errorMessage}</div> : null}
            loading={queryStatus.loading}
            submitting={submitting}
            disabled={submitting || (hasAddressError && !isNewAddressValid)}
            allowRetry
            orderItems={nthReturnsActions.orderData?.items ?? []}
            selectedItems={nthReturnsActions.selectedItems ?? []}
            dropOffLocation={
              nthReturnsActions.returnCart?.pudoOption?.selectedDropOff
                ?.dropOffLocation
            }
            fees={nthFees}
            refund={nthRefund}
            returnCart={nthReturnsActions.returnCart}
            hasAssociatedEmail // in order lookup, email address is required, so yes
            savePreferences={
              config.isUserPreferencesEnabled && state.savePreferences
            }
            preferencesStatus={preferencesStatus}
            reshopOptions={reshopOptions}
            onChange={handleReviewSummaryChange}
            renderAddOn={
              <>
                {stripeAddOn}
                {addressCorrectionAddOn}
                {reminderAddOn}
              </>
            }
            onBack={() => {
              actions.goBack();
              typeForm.scrollToActive();
            }}
            onSubmit={newOnSubmitHandler}
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <Grid className={classes.title} spacing={2} container>
        <Grid xs={12} item>
          <Typography align="center" variant="h1">
            {titleTranslation}
          </Typography>
        </Grid>
      </Grid>
      {queryStatus.loading ? <Loading /> : <OrderDetails />}
    </div>
  );
};
export default Review;
