export const BORDER_WIDTH_UNSELECTED = 1;
export const BORDER_WIDTH_SELECTED = 3;
export const ACTION_HEIGHT_SPACING = 5;

export const getBorderSelected = theme =>
  `${BORDER_WIDTH_SELECTED}px solid ${theme.palette.primary.main}`;

export const getBorderUnselected = theme =>
  `${BORDER_WIDTH_UNSELECTED}px solid #ccc`;

export const getPadding = theme => theme.spacing(3);
