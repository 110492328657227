import every from "lodash/every";
import { find, pick, set, sortBy } from "lodash/fp";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import some from "lodash/some";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Link } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { ItemSelector, ReturnReasons } from "@narvar/nth-kit-returns-headless";

import { config } from "../../config";
import { useFrameDimensions } from "../../contexts/frameDimensions";
import { useNthReturnsActions } from "../../contexts/nthContext";
import {
  CHOOSE_ITEMS_STEP,
  CHOOSE_METHOD_STEP,
  ORDER_LOOKUP_STEP,
  useRegisterStepStatus,
  useReturnStepIsActive,
  useReturnStepsActions,
  useReturnStepsState,
} from "../../contexts/returnSteps";
import { useTypeForm } from "../../contexts/typeForm";
import useCustomer from "../../hooks/useCustomer";
import useLoginSession from "../../hooks/useLoginSession";
import {
  buildCarouselItems,
  createCarouselItemKey,
  eventDataToNewSelectedItem,
  isSameCarouselItem,
  itemIneligibleReasons,
  newSelectedItemToState,
  useNthAllItems,
  useNthExchangeProps,
  useNthSubmittedReturns,
} from "../../modules/items";
import { makeRootStyles } from "../../theme/styles";
import Item from "./Item";
import ItemsCarousel from "./ItemsCarousel";

export const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingBottom: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    supplementaryText: {
      marginBottom: theme.spacing(3),
    },
    chooseButton: {
      marginTop: theme.spacing(1),
    },
    newUiDisclaimer: {
      padding: theme.spacing(1.5, 1.5, 0, 1.5),
    },
  }),
  { name: "N0ChooseItems" },
);

const isEligibleItem = (item, returnReasons) => {
  if (!item) return false;

  const {
    quantity,
    returnReason,
    childReturnReason,
    comment,
    pictures = [],
  } = item;

  // quantity
  if (!quantity || quantity <= 0) return false;

  // picture required
  const rrLookup = returnReasons.reduce((memo, rr) => {
    memo[rr.id] = rr;
    return memo;
  }, {});
  // source of data maybe dirty (pictures disabled but required)
  if (
    rrLookup?.[returnReason]?.enableCustomerPictures &&
    rrLookup?.[returnReason]?.requireCustomerPictures &&
    pictures.length < 1
  )
    return false;

  // test on childReturnReason if it is given
  const target = childReturnReason || item;
  if (!target.eligible) return false; // return reason / child reason is eligible
  if (!rrLookup[target.returnReason]) return false; // reason code exists
  // comment required
  if (rrLookup[target.returnReason]?.isCommentMandatory) {
    if (!comment || typeof comment !== "string" || !comment.trim()) {
      return false;
    }
  }

  return true;
};

const ChooseItems = ({ orderFetchData }) => {
  const itemsCarouselRef = useRef(null);
  const classes = useStyles();
  const state = useReturnStepsState();
  const actions = useReturnStepsActions();
  const loginSession = useLoginSession();
  const [localSelected, setLocalSelected] = useState([]);

  const { order, returnReasons, returnHistory = [] } = orderFetchData;
  const selectedByKey = useMemo(
    () => keyBy(localSelected, createCarouselItemKey),
    [localSelected],
  );
  const [continuePendingByKey, setContinuePendingByKey] = useState({});
  const localItems = useMemo(
    () => buildCarouselItems(order.items, localSelected),
    [localSelected, order.items],
  );
  const sortedLocalItems = useMemo(() => {
    return sortBy(i => !i.someReasonsEligible, localItems);
  }, [localItems]);
  const addExtraSpace = useMemo(
    () =>
      some(localSelected, item => {
        const found = order.items.find(i => i.id === item.id);
        return found.returnableQuantity > 1;
      }),
    [order.items, localSelected],
  );
  const hasStoreCredit = some(state.items, "isStoreCredit");
  const showItemsLimitDisclaimer = order.items.length >= 100;

  const isActiveStep = useReturnStepIsActive(CHOOSE_ITEMS_STEP);
  const showNextBtn = isActiveStep;

  const isGiftReturnsEnabled =
    config.isGiftZipEnabled || config.isGiftReceiptEnabled;
  const showCreateGiftReturn =
    isGiftReturnsEnabled && !state.guestReturn && !!state.csid;

  const handleCreateGiftReturn = () => {
    const zip = order.fromZip || order.billingZip;
    const { orderNumber } = state;
    loginSession.logout();
    actions.setOrderNumber(orderNumber);
    actions.setEmail("");
    actions.setGiftField(zip);
    actions.setIsGuestReturn(true);
  };

  const getSelectedItem = useCallback(
    item => selectedByKey[createCarouselItemKey(item)],
    [selectedByKey],
  );

  const addLocalItem = useCallback(
    item => {
      setLocalSelected(items => [
        ...items.filter(
          i =>
            !!i.type &&
            !isSameCarouselItem(i, item) &&
            order.items.findIndex(s => s.id === i.id) > -1,
        ),
        item,
      ]);
    },
    [order.items],
  );

  const removeLocalItem = useCallback(item => {
    setLocalSelected(items => items.filter(i => !isSameCarouselItem(i, item)));
    actions.clearLineItemIntension({
      lineItemId: item.id,
      localId: item.localId,
    });
  }, []);

  const setStateToLocalItem = useCallback(
    (item, data = {}) => {
      let localItem = {
        id: item.id,
        localId: item.localId,
        ...getSelectedItem(item),
        ...data,
      };
      // TODO: isStoreCredit items will come in as return items in submit_return
      if (localItem?.exchange?.isStoreCredit) {
        localItem.type = "return";
        localItem.exchange = undefined;
        localItem.isStoreCredit = true;
      }
      addLocalItem(localItem);
      actions.setLineItemInitialIntension({
        lineItemId: item.id,
        localId: item.localId,
        type: localItem.type,
        exchangeEnabled: item.allowExchange,
      });
    },
    [addLocalItem, getSelectedItem],
  );

  const setPicturesForItem = useCallback(
    (item, pictures) => {
      setStateToLocalItem(item, { pictures });
    },
    [setStateToLocalItem],
  );

  const handleRemoveItem = useCallback(item => removeLocalItem(item), [
    removeLocalItem,
  ]);

  useEffect(() => {
    const keys = Object.keys(selectedByKey);
    setContinuePendingByKey(pick(keys));
  }, [selectedByKey]);

  const handleChangeContinuePending = useCallback((item, isContinuePending) => {
    const key = createCarouselItemKey(item);
    setContinuePendingByKey(set(key, isContinuePending));
  }, []);

  const handleChangeReason = useCallback(
    (item, reason) => {
      const { eligible, ineligibleReason, returnReason } = reason;

      const hasChildReturnReason = item.eligibilityCriteria.some(
        i => i.returnReasonParent === returnReason,
      );

      setStateToLocalItem(item, {
        eligible,
        ineligibleReason,
        returnReason,
        childReturnReason: hasChildReturnReason ? { returnReason: null } : null,
      });
    },
    [setStateToLocalItem],
  );

  const handleChangeChildReason = useCallback(
    (item, reason) => {
      const { eligible, ineligibleReason, returnReason } = reason;

      setStateToLocalItem(item, {
        eligible,
        ineligibleReason,
        childReturnReason: { eligible, ineligibleReason, returnReason },
      });
    },
    [setStateToLocalItem],
  );

  const handleChangeQuantity = useCallback(
    (item, quantity) => {
      setStateToLocalItem(item, { quantity });
    },
    [setStateToLocalItem],
  );

  const handleChangeComment = useCallback(
    (item, comment) => {
      setStateToLocalItem(item, { comment: (comment || "").trim() });
    },
    [setStateToLocalItem],
  );

  const handleNextStep = useCallback(() => {
    actions.goToStep(CHOOSE_METHOD_STEP);
  }, []);

  const isValidSelected = useMemo(() => {
    if (state.items.length < 1) return false;
    if (Object.values(continuePendingByKey).some(d => d)) return false; // no pending (eg. opened exchange variants, pictures upload or comment)

    const allItemsEligible = every(localSelected, item =>
      isEligibleItem(item, returnReasons),
    );
    return allItemsEligible;
  }, [returnReasons, state, localSelected, continuePendingByKey]);

  const actionNode = useMemo(() => {
    return (
      <Grid xs={12} sm={6} md={4} item>
        <Button
          id="choose-items-next"
          className={classes.chooseButton}
          color="primary"
          disabled={!isValidSelected}
          onClick={handleNextStep}
          size="large"
          variant="contained"
          fullWidth>
          <strong>{config.translations.choose_items_next_button_label}</strong>
        </Button>
      </Grid>
    );
  }, [handleNextStep, isValidSelected]);

  const commonIneligibleReasons = intersection(
    ...sortedLocalItems.map(itemIneligibleReasons),
  );

  useEffect(() => {
    const eligibleItems = localSelected.filter(item =>
      isEligibleItem(item, returnReasons),
    );
    actions.setSelectedItems(eligibleItems);
  }, [actions, localSelected, returnReasons]);

  const supplementaryText =
    config.translations.choose_items_supplementary &&
    config.translations.choose_items_supplementary !== "" &&
    config.translations.choose_items_supplementary !== " ";

  // TODO: fix the backend then use order.eligible instead
  const orderEligible = useMemo(
    () => order.items.some(item => item.someReasonsEligible),
    [order],
  );

  /***************************************************************************************************************
   * New design with `nth` components
   ***************************************************************************************************************/
  const useNewDesign = config.isNthUi;
  const isTrack = state.mode === "track";
  useRegisterStepStatus(ORDER_LOOKUP_STEP, { skipped: useNewDesign });

  const typeForm = useTypeForm();
  const { bottomOfViewport, topOfViewport } = useFrameDimensions();
  const viewportAnchor = useMemo(
    () => ({
      offsetTop: topOfViewport(0),
      offsetBottom: bottomOfViewport(0),
    }),
    [topOfViewport, bottomOfViewport],
  );
  const nthReturnsActions = useNthReturnsActions();
  const [selectingReasonId, setSelectingReasonId] = useState();
  const [newSelectingItem, setNewSelectingItem] = useState();
  const [newSelectedItems, setNewSelectedItems] = useState([]);
  const customer = useCustomer();
  const newLineItems = useNthAllItems({ localItems: sortedLocalItems });
  const {
    exchangeVariants,
    exchangeOptions,
    status: exchangeStatus,
    setExchangeVariantInfo,
  } = useNthExchangeProps({ selectingItem: newSelectingItem });
  const {
    submittedReturns,
    returnActionStatus,
    cancelStatus,
  } = useNthSubmittedReturns({
    allItems: newLineItems,
  });

  const stepsProps = useMemo(() => {
    if (!newSelectingItem) return {};
    const reason = orderFetchData.returnReasonsById[selectingReasonId];
    const parentReason = orderFetchData.returnReasonsById[reason?.parentId];
    return {
      exchanges: {
        enabled: newSelectingItem?.eligibilities?.exchange?.isEligible,
        shopNow: config.isShopNowEnabled && config.isShopNowWithCreditEnabled,
      },
      pictures: {
        // picture requirements are set in parent reason
        enabled: (parentReason ?? reason)?.enableCustomerPictures,
        required: (parentReason ?? reason)?.requireCustomerPictures,
        maxCount: 2,
        maxSize: 10 << 20, // 10MB
      },
      comments: {
        required: reason?.isCommentMandatory,
      },
    };
  }, [newSelectingItem, selectingReasonId, orderFetchData.returnReasonsById]);

  const orderIneligibleReason =
    orderEligible || isTrack
      ? undefined
      : order.ineligibleDisplayText ??
        commonIneligibleReasons.join("<br/>") ??
        config.translations.choose_items_none_eligible;

  const disclaimerNew = useMemo(() => {
    if (!showItemsLimitDisclaimer && !showCreateGiftReturn) return null;

    return (
      <>
        {showItemsLimitDisclaimer && (
          <div className={classes.newUiDisclaimer}>
            <Typography align="center">
              {config.translations.choose_items_line_items_limit_remarks}
            </Typography>
          </div>
        )}

        {showCreateGiftReturn && (
          <div className={classes.newUiDisclaimer}>
            <Link onClick={handleCreateGiftReturn}>
              {config.translations.choose_items_cs_create_gift_return}
            </Link>
          </div>
        )}
      </>
    );
  }, [showItemsLimitDisclaimer, showCreateGiftReturn]);

  const loading = exchangeStatus.loading || cancelStatus.loading;

  return (
    <Grid
      className={classes.root}
      justifyContent="center"
      spacing={2}
      container>
      {useNewDesign ? (
        <Grid xs={12} item>
          {!newSelectingItem && (
            <ItemSelector
              progressiveStep
              activeStep={isActiveStep}
              title={orderIneligibleReason}
              customer={customer}
              allItems={isTrack ? [] : newLineItems}
              selectedItems={newSelectedItems}
              submittedReturns={submittedReturns}
              splitSelectedItems
              viewportAnchor={viewportAnchor}
              cancelReturnStatus={returnActionStatus}
              showOfflineReturns="ineligible"
              lineItemGroupings={order.orderLineItemGroupings}
              itemGroupingModeEnabled={config.shopifyCollectiveEnabled}
              disclaimer={disclaimerNew}
              onClick={event => {
                setNewSelectingItem(event.value.item);
                actions.setSelectedItems(state.items); // reset step, clean up the UI when user clicking from a later step back to item selection

                const newSelected = find({
                  itemId: event.value.item.itemId,
                  sku: event.value.item.sku,
                  localId: event.value.item.localId,
                })(newSelectedItems);
                if (newSelected) {
                  const selecting = newSelectedItemToState(newSelected);
                  actions.setSelectingItem(selecting);
                }

                typeForm.scrollToActive();
              }}
              onSubmit={event => {
                nthReturnsActions.setOrderData({
                  orderId: order.orderNumber,
                  items: newLineItems,
                });
                nthReturnsActions.setSelectedItems(newSelectedItems);
                handleNextStep();
              }}
            />
          )}
          {isTrack && submittedReturns.length === 0 && (
            <Typography align="center">
              {config.translations.track_app_choose_items_not_found}
            </Typography>
          )}
          {newSelectingItem && (
            <ReturnReasons
              progressiveStep
              activeStep={isActiveStep}
              layout="footerNav"
              loading={loading}
              orderItem={newSelectingItem}
              selectedItem={newSelectedItems.find(
                i =>
                  i.itemId === newSelectingItem.itemId &&
                  i.localId === newSelectingItem.localId,
              )}
              stepsProps={stepsProps}
              exchangeVariants={exchangeVariants}
              exchangeOptions={exchangeOptions}
              onChange={event => {
                const {
                  item,
                  selectedReason,
                  selectedExchangeOptions,
                } = event.value;
                const selected = eventDataToNewSelectedItem(event.value);
                const selecting = newSelectedItemToState(selected);

                setExchangeVariantInfo(selectedExchangeOptions);
                setSelectingReasonId(selectedReason.id);
                actions.setLineItemInitialIntension({
                  lineItemId: item.itemId,
                  localId: item.localId,
                  type: selecting.type, // `event.value.type` may have "exchangeForCredit" which we don't want
                  exchangeEnabled: item.eligibilities.exchange?.isEligible,
                });
                actions.setSelectingItem(selecting);
              }}
              onNext={event => {
                typeForm.scrollToActive();
              }}
              onBack={event => {
                typeForm.scrollToActive();
              }}
              onClose={event => {
                setNewSelectingItem(null);
                setSelectingReasonId(null);
                actions.setSelectingItem(null);
                actions.clearLineItemIntension({
                  lineItemId: newSelectingItem.itemId,
                  localId: newSelectingItem.localId,
                });
                typeForm.scrollToActive();
              }}
              onSubmit={event => {
                const { item, quantity, type } = event.value;

                const isEdit = event.subComponent === "edit";
                // clicked on "No, continue with return"
                if (isEdit && quantity > 0) {
                  setNewSelectingItem(null);
                  actions.setSelectingItem(null);
                  typeForm.scrollToActive();
                  return;
                }

                let updates = newSelectedItems.filter(
                  i => i.itemId !== item.itemId || i.localId !== item.localId,
                );
                if (quantity) {
                  updates = []
                    .concat(updates)
                    .concat(eventDataToNewSelectedItem(event.value));
                }
                setNewSelectedItems(updates);
                setNewSelectingItem(null);

                const selectedItems = updates.map(newSelectedItemToState);

                actions.setSelectedItems(selectedItems);
                actions.setSelectingItem(null);
                if (isEdit && quantity === 0) {
                  actions.clearLineItemIntension({
                    lineItemId: item.itemId,
                    localId: item.localId,
                  });
                } else {
                  actions.confirmLineItemIntension({
                    lineItemId: item.itemId,
                    localId: item.localId,
                    type,
                  });
                }
                typeForm.scrollToActive();
              }}
            />
          )}
        </Grid>
      ) : (
        <>
          <Grid xs={12} item>
            {!orderEligible ? (
              order.ineligibleDisplayText ? (
                <Typography
                  align="center"
                  className={classes.title}
                  variant="h2"
                  dangerouslySetInnerHTML={{
                    __html: order.ineligibleDisplayText,
                  }}
                />
              ) : (
                <>
                  {isEmpty(commonIneligibleReasons) && (
                    <Typography
                      align="center"
                      className={classes.title}
                      variant="h2"
                      dangerouslySetInnerHTML={{
                        __html: config.translations.choose_items_none_eligible,
                      }}
                    />
                  )}
                  {commonIneligibleReasons.map(commonIneligibleReason => (
                    <Typography
                      align="center"
                      className={classes.title}
                      variant="h2"
                      key={commonIneligibleReason}
                      dangerouslySetInnerHTML={{
                        __html: commonIneligibleReason,
                      }}
                    />
                  ))}
                </>
              )
            ) : (
              <>
                <Typography
                  align="center"
                  className={classes.title}
                  variant="h1">
                  {config.translations.choose_items_title}
                </Typography>
                {supplementaryText && (
                  <Typography
                    align="center"
                    className={classes.supplementaryText}
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: config.translations.choose_items_supplementary,
                    }}
                  />
                )}
              </>
            )}
            <ItemsCarousel ref={itemsCarouselRef} data-styleid="item-carousel">
              {sortedLocalItems.map(item => {
                const key = createCarouselItemKey(item);

                return (
                  <Item
                    key={key}
                    addExtraSpace={addExtraSpace}
                    disabled={
                      !order.eligible ||
                      !item.someReasonsEligible ||
                      !item.returnableQuantity
                    }
                    enableExchange={config.isExchangesEnabled}
                    itemIneligibleReasons={itemIneligibleReasons(item)}
                    item={item}
                    onChangeContinuePending={handleChangeContinuePending}
                    onChangeReason={handleChangeReason}
                    onChangeChildReason={handleChangeChildReason}
                    onChangeQuantity={handleChangeQuantity}
                    onChangeComment={handleChangeComment}
                    onChoose={setStateToLocalItem}
                    onRemove={handleRemoveItem}
                    orderFetchData={orderFetchData}
                    setPicturesForItem={setPicturesForItem}
                    selectedState={selectedByKey[key]}
                  />
                );
              })}
            </ItemsCarousel>
          </Grid>
          {showCreateGiftReturn && (
            <Grid xs={12} item>
              <Typography align="center">
                <Link onClick={handleCreateGiftReturn}>Create Gift Return</Link>
              </Typography>
            </Grid>
          )}
          {showNextBtn && actionNode}
          {hasStoreCredit && (
            <Grid xs={12} item>
              <Typography align="center">
                {config.translations.choose_items_shopnow_remarks}
              </Typography>
            </Grid>
          )}
          {showItemsLimitDisclaimer && (
            <Grid xs={12} item>
              <Typography align="center">
                {config.translations.choose_items_line_items_limit_remarks}
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
export default ChooseItems;
