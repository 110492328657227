import React, { useEffect, useState } from "react";
import clsx from "clsx";
import get from "lodash/get";

import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Confirmation as NthConfirmation } from "@narvar/nth-kit-returns-headless";

import {
  BOXLESS_METHOD,
  METHOD_IN_STORE,
  METHOD_KEEP_THE_ITEM,
  METHOD_SHIP_ON_YOUR_OWN,
  METHOD_XPO,
  PRINTERLESS_METHOD,
} from "../../../retailer-app/constants/returns";
import { BOXLESS_ONLY_CARRIERS } from "../../../retailer-app/constants/carriers";
import { useReturnStepsState } from "../../contexts/returnSteps";
import { makeRootStyles } from "../../theme/styles";
import ConfirmationCheckIcon from "./ConfirmationCheckIcon";
import { useTheme } from "@material-ui/styles";

import { redirectToShop, openLabel } from "../../../shared/modules/frame";
import { config } from "../../config";
import { useNthReturnsActions } from "../../contexts/nthContext";
import {
  toReturnMethodPreference,
  useConsumerPreferences,
} from "../../data/consumerPreferences";
import titan from "../../../shared/modules/titan";
import useCustomer from "../../hooks/useCustomer";

const {
  translations: {
    return_confirmation_title_keep_item,
    return_confirmation_title,
    return_confirmation_subtitle_keep_item,
    return_confirmation_subtitle_keep_item_no_refund,
    return_confirmation_subtitle,
    return_confirmation_subtitle_label_retry_enqueued,
  },
} = config;

const useStyles = makeRootStyles(
  theme => ({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(12),
      display: "flex",
      alignItems: "center",
    },
    checkIcon: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "5rem",
    },
    title: {},
    subtitle: {},
    supplementaryText: {},
    gutterBottom: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up("md")]: {
        marginBottom: theme.spacing(2),
      },
    },
    labelButton: {
      marginBottom: theme.spacing(2),
    },
    continueShoppingButton: {},
  }),
  { name: "N0Confirmation" },
);

function Confirmation({ orderFetchData }) {
  const [show, setShow] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const state = useReturnStepsState();
  const {
    selectedReturnMethod,
    refundTotal,
    submitReturnResultCode,
    savePreferences,
  } = state;
  const { email: displayEmail } = useCustomer();

  useEffect(() => {
    setTimeout(() => setShow(true), 400);
  }, []);

  const title =
    selectedReturnMethod.id === METHOD_KEEP_THE_ITEM
      ? return_confirmation_title_keep_item
      : return_confirmation_title;

  const subtitle = (() => {
    if (submitReturnResultCode === "label_retry_enqueued") {
      return return_confirmation_subtitle_label_retry_enqueued.replace(
        "%{email}",
        displayEmail,
      );
    } else if (selectedReturnMethod.id === METHOD_KEEP_THE_ITEM) {
      if (refundTotal === 0) {
        return return_confirmation_subtitle_keep_item_no_refund.replace(
          "%{email}",
          displayEmail,
        );
      } else {
        return return_confirmation_subtitle_keep_item.replace(
          "%{email}",
          displayEmail,
        );
      }
    } else {
      return return_confirmation_subtitle.replace("%{email}", displayEmail);
    }
  })();

  const supplementaryText =
    config.translations.return_confirmation_supplementary;
  const renderSupplementaryText =
    supplementaryText && supplementaryText != "" && supplementaryText != " ";

  const returnMethod = get(state, "selectedReturnMethod.id");
  const renderInAppLabel =
    config.isInAppLabelEnabled && state.submitResult !== "ok";
  const printLabelText = (() => {
    if (returnMethod == METHOD_SHIP_ON_YOUR_OWN) {
      return config.translations.return_confirmation_print_packing_slip;
    }
    return config.translations.return_confirmation_print_label;
  })();

  /***************************************************************************************************************
   * New design with `nth` components
   ***************************************************************************************************************/
  const useNewDesign = config.isNthUi;
  const nthReturnsActions = useNthReturnsActions();

  const {
    preferencesStatus,
    updateConsumerPreferences,
  } = useConsumerPreferences(state.guestReturn ? "" : state.email);

  useEffect(() => {
    const type = toReturnMethodPreference(selectedReturnMethod);
    if (savePreferences && type) {
      const orderId = orderFetchData.order?.shopifyOrderId?.split("/").pop();
      updateConsumerPreferences(orderId, displayEmail, {
        returnMethods: [type],
        carriers: [],
        locations: [],
      });
    }
  }, [savePreferences]);

  const preferencesSubmitted = preferencesStatus?.status === "success";
  useEffect(() => {
    if (preferencesSubmitted) {
      titan.track("promotion_clicked", {
        props: { promotion_id: "user_preferences" },
      });
    }
  }, [preferencesSubmitted]);

  if (useNewDesign) {
    // nth type with default translation: store, mail, printerless_mail, concierge
    const type =
      state.selectedReturnMethod.id === METHOD_IN_STORE
        ? "store"
        : state.selectedReturnMethod.id === METHOD_SHIP_ON_YOUR_OWN
        ? "ship_on_your_own"
        : state.selectedReturnMethod.id === METHOD_XPO
        ? "home_pickup"
        : state.selectedReturnMethod.id === METHOD_KEEP_THE_ITEM
        ? "keep_the_item"
        : state.selectedReturnMethod.qr === BOXLESS_METHOD ||
          (BOXLESS_ONLY_CARRIERS.includes(state.selectedReturnMethod.id) &&
            state.selectedReturnMethod.qr === PRINTERLESS_METHOD)
        ? "boxless"
        : state.selectedReturnMethod.qr === PRINTERLESS_METHOD
        ? "printerless"
        : "mail";
    console.debug("method", state.selectedReturnMethod);
    console.debug("type", type);
    return (
      <Fade in={show} timeout={800}>
        <div className={classes.root}>
          <Grid justifyContent="center" spacing={2} container>
            <Grid xs={12} item>
              <NthConfirmation
                type={type}
                refundMethod={state.selectedReturnCreditMethod.id}
                noInstructions={
                  state.selectedReturnMethod.id === METHOD_KEEP_THE_ITEM
                }
                email={displayEmail}
                shipment={{
                  items: nthReturnsActions.selectedItems.map(item => ({
                    ...item,
                    reason: {},
                  })),
                  returnLabels: renderInAppLabel
                    ? [
                        {
                          labelType: "SHIPPING_LABEL", // PACKING_SLIP, SHIPPING_LABEL, PACKING_SLIP_AND_SHIPPING_LABEL, QR_CODE
                          mimeType: "application/pdf",
                          labelUrl: state.submitResult,
                        },
                      ]
                    : [],
                }}
                providerMoniker={
                  nthReturnsActions.selectedPudoOption?.pudoProvider?.moniker
                }
                location={
                  nthReturnsActions.returnCart?.pudoOption?.selectedDropOff
                    ?.dropOffLocation
                }
                preferencesStatus={
                  savePreferences ||
                  preferencesStatus?.profileStatus === "active"
                    ? preferencesStatus
                    : undefined
                }
              />
            </Grid>
          </Grid>
        </div>
      </Fade>
    );
  }

  return (
    <Fade in={show} timeout={800}>
      <div className={classes.root}>
        <Grid justifyContent="center" spacing={2} container>
          <Grid xs={12} item>
            <div className={clsx(classes.checkIcon, classes.gutterBottom)}>
              <ConfirmationCheckIcon color={theme.palette.primary.main} />
            </div>
            <Typography
              className={clsx(classes.title, classes.gutterBottom)}
              align="center"
              variant="h1">
              {title}
            </Typography>
            <Typography
              className={clsx(classes.subtitle, classes.gutterBottom)}
              align="center"
              variant="body1">
              {subtitle}
            </Typography>
            {renderSupplementaryText && (
              <Typography
                className={clsx(
                  classes.supplementaryText,
                  classes.gutterBottom,
                )}
                align="center"
                variant="body1">
                {supplementaryText}
              </Typography>
            )}
          </Grid>
          {renderInAppLabel && (
            <Grid xs={12} sm={8} md={6} lg={4} item>
              <Link
                onClick={() => openLabel(state.submitResult)}
                target="_blank"
                underline="none">
                <Button
                  id="confirmation-shipping-label"
                  className={classes.labelButton}
                  color="primary"
                  size="large"
                  variant="contained"
                  fullWidth>
                  {printLabelText}
                </Button>
              </Link>
              <Typography align="center">
                <Link
                  className={classes.continueShoppingButton}
                  id="confirmation-continue-shopping"
                  onClick={redirectToShop}>
                  {config.translations.return_confirmation_continue_shopping}
                </Link>
              </Typography>
            </Grid>
          )}
          {!renderInAppLabel && (
            <Grid xs={12} sm={8} md={6} lg={4} item>
              <Button
                id="confirmation-continue-shopping"
                className={classes.continueShoppingButton}
                color="primary"
                onClick={redirectToShop}
                size="large"
                variant="contained"
                fullWidth>
                {config.translations.return_confirmation_continue_shopping}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </Fade>
  );
}
export default Confirmation;
