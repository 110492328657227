import { useMemo, useCallback, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import get from "lodash/get";
import {
  calculateChecksum,
  directUpload,
  getFileMetadata,
} from "../../shared/modules/fileUpload";
import { useRequestStatus } from "../../shared/modules/hooks";
import { useMergeRequestStatus } from "../../shared/modules/object";

const CREATE_DIRECT_UPLOAD = gql`
  mutation createDirectUpload($input: CreateDirectUploadInput!) {
    createDirectUpload(input: $input) {
      url
      headers
      blobId
      signedBlobId
    }
  }
`;
export function useUploadPictures(variables) {
  const [mutate, mutated] = useMutation(CREATE_DIRECT_UPLOAD);
  const data = useMemo(() => get(mutated, "data.createDirectUpload"), [
    mutated,
  ]);
  const statusMutated = useRequestStatus(mutated);
  const [statusRequest, setDirectUploadStatus] = useState({
    called: false,
    error: false,
    loading: false,
  });
  const status = useMergeRequestStatus(statusRequest, statusMutated);

  const createDirectUpload = useCallback(
    input => mutate({ variables: { input } }),
    [mutate],
  );

  const direct = useCallback(async (data, file) => {
    try {
      setDirectUploadStatus(c => ({ ...c, called: true, loading: true }));

      await directUpload(
        data.createDirectUpload.url,
        { ...JSON.parse(data.createDirectUpload.headers) },
        file,
      );

      setDirectUploadStatus(c => ({ ...c, error: false, loading: false }));
    } catch (err) {
      setDirectUploadStatus(c => ({ ...c, error: true }));
      throw err; // throw the error so that we can stop the following mutation sequence
    }
  }, []);

  const uploadImage = useCallback(
    async file => {
      const checksum = await calculateChecksum(file);
      const metadata = await getFileMetadata(file);

      const { data } = await createDirectUpload({
        filename: file.name,
        byteSize: metadata.byte_size,
        checksum,
        contentType: metadata.content_type,
      });

      await direct(data, file);

      return data?.createDirectUpload;
    },
    [createDirectUpload, direct],
  );

  return useMemo(
    () => ({
      data,
      status,
      uploadImage,
    }),
    [data, status, uploadImage],
  );
}
