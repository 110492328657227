export const STATUS_PREAUTHORIZATION = "preauthorization";
export const STATUS_STARTED = "started";
export const STATUS_REQUESTED = "requested";
export const STATUS_INITIATED = "initiated";
export const STATUS_DELIVERED_TO_RETAILER = "delivered_to_retailer";
export const STATUS_DELIVERED_BUT_NOT_RECEIVED = "delivered_but_not_received";
export const STATUS_ON_ITS_WAY_TO_RETAILER = "on_its_way_to_retailer";
export const STATUS_RECEIVED_BY_RETAILER = "received_by_retailer";
export const STATUS_PROCESSING = "processing";
export const STATUS_APPROVED = "approved";
export const STATUS_REJECTED = "rejected";
export const STATUS_RESOLVE_MANUALLY_WITHOUT_AUTOMATION =
  "resolve_manually_without_automation";
export const STATUS_REFUNDED = "refunded";
export const STATUS_REFUNDED_AND_EXCHANGED = "refunded_and_exchanged";
export const STATUS_REFUNDED_RETURN_AWAITING_EXCHANGE_COMPLETION =
  "refunded_return_awaiting_exchange_completion";
export const STATUS_EXCHANGE_PROCESSED = "exchange_processed";
export const STATUS_EXCEPTION = "exception";
export const STATUS_CANCELLED_BY_RETAILER = "cancelled_by_retailer";
export const STATUS_CANCELLED_BY_USER = "cancelled_by_user";
export const STATUS_OUT_OF_STOCK_EXCEPTION = "out_of_stock_exception";
export const STATUS_NEEDS_MANUAL_APPROVAL = "needs_manual_approval";

export const METHOD_KEEP_THE_ITEM = "keep_the_item";
export const METHOD_FEDEX = "fedex";
export const METHOD_XPO = "xpo";
export const METHOD_IN_STORE = "return_in_store";
export const METHOD_SHIP_ON_YOUR_OWN = "ship_on_your_own";
export const METHOD_BY_MAIL = "byMail";
export const METHOD_BY_MAIL_AND_IN_STORE = `${METHOD_BY_MAIL} ${METHOD_IN_STORE}`;

export const RETURN_CREDIT_METHOD_SHOPNOW = "shop_now";
export const RETURN_CREDIT_METHOD_GIFT_CARD = "gift_card";
export const RETURN_CREDIT_METHOD_RESHOP = "reshop";
export const RETURN_CREDIT_METHOD_REFUND = "original_payment";
export const PRINTERLESS_METHOD = "PRINTERLESS";
export const BOXLESS_METHOD = "BOXLESS";

export const REFUND_METHOD_ORIGINAL_PAYMENT = "original_payment";
export const REFUND_METHOD_GIFT_CARD = "gift_card";
export const REFUND_METHOD_COUPON_CODE = "coupon_code";
