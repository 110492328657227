import { useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import { getAddressError } from "../../shared/modules/decodeError";
import { memoObjectByKeyValues } from "../../shared/modules/object";

export const QUERY = gql`
  query getReturnMethods(
    $returnItems: [ReturnCalculationInput!]!
    $exchangeItems: [ExchangeItemInput!]!
    $orderNumber: String!
    $email: String!
    $locale: String
    $fromAddress: FromAddressInput
    $csid: String
  ) {
    returnMethods(
      returnItems: $returnItems
      exchangeItems: $exchangeItems
      orderNumber: $orderNumber
      email: $email
      locale: $locale
      fromAddress: $fromAddress
      csid: $csid
    ) {
      id
      carrierName
      carrierService
      carrierId
      displayName
      displayDescription
      displayPrice
      returnMethodDisplayPrice
      price
      shouldDisplayLocations
      keepItemIds
      qr
      feeBreakdown {
        fee
        currency
        description
        displayWithReturnMethod
        formattedFee
      }
      maxLabelRequests
      consolidationRadius
    }
  }
`;

export function useReturnMethods(variables) {
  variables.returnItems = variables.returnItems.map(ri => ({
    ...ri,
    pictures: [],
  }));
  variables.exchangeItems = variables.exchangeItems.map(ri => ({
    ...ri,
    returnItem: { ...ri.returnItem, pictures: [] },
  }));
  const saved = useQuery(QUERY, { variables });
  const addressError = getAddressError(saved.error);

  const queryStatus = memoObjectByKeyValues({
    loading: saved.loading,
    error: saved.error,
  });
  const { returnMethods } = saved.data || {};

  return useMemo(
    () => ({
      queryStatus,
      returnMethods: returnMethods || [],
      addressError,
      refetch: saved.refetch,
    }),
    [queryStatus, returnMethods],
  );
}
