import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
  cloneElement,
} from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Menu from "@material-ui/core/Menu";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";

const anchorOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

const transformOrigin = {
  vertical: "top",
  horizontal: "center",
};

const useStyles = makeStyles(
  theme => ({
    menuRoot: {
      marginTop: theme.spacing(1),
    },
  }),
  { name: "N0ButtonSelect" },
);

const ButtonSelect = ({
  children,
  className,
  value,
  disabled,
  autoClose,
  variant = "contained",
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const menuWidth = anchorRef?.current
    ? anchorRef?.current?.offsetWidth - 2
    : 0;

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (disabled && open) {
      setOpen(false);
    }
  }, [disabled]);

  const createOnClickWrapper = func =>
    function buttonSelectOnClickWrapper(...args) {
      const res = func(...args);
      setOpen(false); // close menu after child item successfully called the onClick function
      return res;
    };

  const _children = useMemo(() => {
    if (autoClose && children) {
      const childrenArray = Array.isArray(children) ? children : [children];
      return childrenArray.map(c => {
        const onClick = c?.props?.onClick;
        if (
          onClick &&
          typeof onClick === "function" &&
          onClick.name !== "buttonSelectOnClickWrapper"
        ) {
          return cloneElement(c, {
            onClick: createOnClickWrapper(onClick),
          });
        }
        return c;
      });
    }
    return children;
  }, [autoClose, children]);

  return (
    <>
      <ButtonGroup
        className={className}
        disabled={disabled}
        variant={variant}
        color="primary"
        ref={anchorRef}
        aria-label="split button">
        <Button onClick={handleToggle} variant={variant}>
          {value}
        </Button>
        <Button
          variant={variant}
          color="primary"
          size="small"
          aria-owns={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        MenuListProps={{
          dense: true,
          style: { width: menuWidth },
        }}
        anchorEl={anchorRef.current}
        anchorOrigin={anchorOrigin}
        className={classes.menuRoot}
        elevation={4}
        getContentAnchorEl={null}
        onClose={handleClose}
        open={open}
        transformOrigin={transformOrigin}
        keepMounted>
        {_children}
      </Menu>
    </>
  );
};

export default ButtonSelect;
