import { useCallback, useMemo, useState } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { getHandledErrorMessage } from "../../shared/modules/decodeError";
import { config } from "../config";
import { memoObjectByKeyValues } from "../../shared/modules/object";

export const GET_RETURN_LOCATIONS = gql`
  query GetReturnLocations(
    $countryCode: String!
    $zipcode: String!
    $carrier: String!
    $qr: String
    $locale: String
    $consolidationRadius: Int
  ) {
    returnLocations(
      countryCode: $countryCode
      zipcode: $zipcode
      carrier: $carrier
      qr: $qr
      locale: $locale
      consolidationRadius: $consolidationRadius
    ) {
      id
      storeAddress {
        address1
        address2
        city
        phone
        state
        zip
      }
      storeName
      storeName2
      logoUrl
      latitude
      longitude
    }
  }
`;

export function useReturnLocations() {
  const [called, setCalled] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const state = useMemo(
    () => ({
      called: called,
      data: data,
      error: error,
      loading: loading,
    }),
    [called, data, error, loading],
  );

  const getLocations = useCallback(
    function getLocations({
      countryCode,
      provinceCode,
      zipcode,
      carrier = "in_store",
      qr = "",
      consolidationRadius = null,
    }) {
      async function getLocationsAsync() {
        const skip = !countryCode || !zipcode || !carrier;
        if (skip) return;

        setCalled(true);
        setLoading(true);

        // SHOPZ-2595: For `ups` Puerto Rico is treated as a country
        const country =
          carrier === "ups" && countryCode === "US" && provinceCode === "PR"
            ? "PR"
            : countryCode;

        try {
          const { data } = await client.query({
            query: GET_RETURN_LOCATIONS,
            variables: {
              countryCode: country,
              zipcode,
              carrier,
              qr,
              consolidationRadius,
            },
          });

          setData(data.returnLocations);
          setError(null);
        } catch (err) {
          setData([]);
          setError(
            getHandledErrorMessage(
              err,
              config.translations.general_loading_error,
            ),
          );
        } finally {
          setLoading(false);
        }
      }

      getLocationsAsync();
    },
    [client],
  );

  const reset = useCallback(() => {
    setData([]);
    setError(null);
    setCalled(false);
    setLoading(false);
  }, []);

  const actions = memoObjectByKeyValues({
    getLocations,
    reset,
  });

  return useMemo(() => ({ actions, state }), [actions, state]);
}
