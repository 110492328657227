import {
  compose,
  filter,
  find,
  flatten,
  fromPairs,
  get,
  getOr,
  groupBy,
  identity,
  intersection,
  map,
  max,
  merge,
  omit,
  range,
  reduce,
  set,
  sortBy,
  stubTrue,
  sumBy,
  uniq,
} from "lodash/fp";
import { useMemo, useState } from "react";

import { getTranslation } from "../../shared/modules/config";
import { getReshopCancelError } from "../../shared/modules/decodeError";
import { toggleContainerClass } from "../../shared/modules/frame";
import locale from "../../shared/modules/lang";
import { memoObjectByKeyValues, renameKeys } from "../../shared/modules/object";
import { simplifyReturnStatus } from "../../shared/modules/returnStatuses";
import { interpolate } from "../../shared/modules/template";
import { config } from "../config";
import {
  useOrderFetchData,
  useReturnStepsState,
} from "../contexts/returnSteps";
import { useCancelReturn } from "../data/cancelReturn";
import { useChangeRefundMethod } from "../data/changeRefundMethod";
import useEvenExchangeProduct from "../data/evenExchangeProduct";
import useCustomer from "../hooks/useCustomer";
import useLoginSession from "../hooks/useLoginSession";
import Sparsem, {
  UNAVAILABLE_IN_ANY_SET,
  UNAVAILABLE_IN_THIS_SET,
} from "./sparsem";

export const buildReturnItem = (item, lineItem, returnReasonsById = {}) => {
  const { comment, quantity, pictures } = item;
  const childReturnReasonId = get("returnReason", item.childReturnReason);
  const childReturnReason = childReturnReasonId
    ? returnReasonsById[childReturnReasonId].reasonTitle
    : undefined;

  return {
    comment,
    quantity,
    pictures,
    returnReason: returnReasonsById[item.returnReason].reasonTitle,
    returnReasonId: item.returnReason,
    childReturnReason,
    childReturnReasonId,
    lineItemId: lineItem.id,
  };
};

export const buildExchangeItem = (item, lineItem, returnReasonsById) => {
  const { exchange } = item;
  const returnItem = buildReturnItem(item, lineItem, returnReasonsById);

  return {
    returnItem,
    newProductId: exchange.productId,
    newProductVariantId: exchange.variantId,
    newProductVariantInfo: exchange.variantInfo,
    newProductSku: exchange.sku,
    newProductName: exchange.name,
    newProductImage: exchange.productImageUrl,
    newProductPriceAmount: exchange.priceAmount,
    newProductPriceCurrency: exchange.priceCurrency,
  };
};

export const buildItemsByType = (items, lineItemsById, returnReasonsById) => {
  const returnItems = items
    .filter(item => item.type === "return")
    .map(item =>
      buildReturnItem(item, lineItemsById[item.id], returnReasonsById),
    );

  let exchangeItems = items
    .filter(item => item.type === "exchange")
    .map(item =>
      // Due to backend limitation, we convert a exchange with quantity > 1
      // into multiple exchanges which quantity is 1
      range(0, item.quantity).map(() =>
        buildExchangeItem(
          set("quantity", 1, item),
          lineItemsById[item.id],
          returnReasonsById,
        ),
      ),
    );
  exchangeItems = flatten(exchangeItems);

  return { returnItems, exchangeItems };
};

// build carousel items (swiper items), if there are exchange items selected and
// that line item still have returnable quantity, create one more carousel item
// for user to select. (item.id, item.localId) are used to distinguish different
// carousel items
export const buildCarouselItems = (orderItems, selected) =>
  orderItems.reduce((remapped, item) => {
    let matching = selected.filter(i => i.id === item.id);
    matching = sortBy("localId", matching);
    const returnItem = matching.find(i => i.type === "return");
    let exchangeItems = matching.filter(i => i.type === "exchange");

    if (exchangeItems.length) {
      // the sum of quantity user selected for exchange and return
      const returningQuantity =
        sumBy(getOr(0, "quantity"), exchangeItems) +
        (returnItem?.quantity ?? 0);
      const returnableQuantity = item.returnableQuantity - returningQuantity;
      exchangeItems = exchangeItems.map(i => ({
        ...item,
        localId: i.localId,
        returnableQuantity: returnableQuantity + (i.quantity ?? 0),
      }));

      let additionalCarouselItems = [];
      if (returnableQuantity || returnItem) {
        const maxExId = max(exchangeItems.map(get("localId")));
        const localId = returnItem?.localId || maxExId + 1;
        additionalCarouselItems.push({
          ...item,
          localId,
          returnableQuantity: returnableQuantity + (returnItem?.quantity ?? 0),
        });
      }

      return [...remapped, ...exchangeItems, ...additionalCarouselItems];
    } else {
      // item is not selected or only selected for return, keep the existing
      // localId or default set to 1
      const localId = returnItem?.localId || 1;
      return [...remapped, { ...item, localId }];
    }
  }, []);

export const buildVariantInfoMap = variantInfo =>
  variantInfo.reduce((result, variant) => {
    result[variant.name] = variant.value;
    return result;
  }, {});

export const getVariantOption = (name, options) =>
  options.find(o => o.name === name);

export const getMostSimilarVariantsForSelection = (
  variants,
  variantsOptions,
  selection,
) => {
  const filterByOption = (arr, optionName) => {
    return arr.filter(
      v => v.variantInfoMap[optionName] === selection[optionName],
    );
  };

  const reduced = variantsOptions.reduce((result, option) => {
    const filtered = filterByOption(result, option.name);
    return filtered.length ? filtered : result;
  }, variants);

  return reduced;
};

export const buildVariantName = option => `option${option.position}`;

export const buildVariantAvailaibityByValue = (
  variantOption,
  currentSelection,
  itemDetails,
) => {
  const { position, values } = variantOption;
  // Names of all previous variants
  const names = itemDetails.options
    .slice(0, position - 1)
    .map(({ name }) => name);
  const filtered = itemDetails.variantInfo.filter(
    v =>
      v.availableForSale &&
      // Filter variants which value matches user selection
      names.every(n => {
        if (!currentSelection[n]) return true;
        const option = getVariantOption(n, itemDetails.options);
        if (!option) return false;
        return v[buildVariantName(option)] === currentSelection[n];
      }),
  );

  if (!filtered.length) return;

  const optionName = buildVariantName(variantOption);

  return values.reduce((result, val) => {
    result[val] = filtered.some(v => v[optionName] === val);
    return result;
  }, {});
};

export const buildVariants = details =>
  details.variantInfo.map(v => {
    const { variantInfo, variantInfoMap } = details.options.reduce(
      (result, option) => {
        const { name } = option;
        const value = v[buildVariantName(option)];
        result.variantInfo.push({ name, value });
        result.variantInfoMap[name] = value;
        return result;
      },
      { variantInfo: [], variantInfoMap: {} },
    );

    return {
      variantInfo,
      variantInfoMap,
      available: v.availableForSale,
      displayPrice: details.displayPrice,
      displayName: v.displayName,
      availabilityStatus: v.availabilityStatus,
      // id: details.id,
      name: v.displayName || v.option0 || details.name,
      productImageAltTxt: v.imageAlt || details.productImageAltTxt,
      productImageUrl: v.imageSrc || details.productImageUrl,
      variantId: v.variantId,
      productId: v.productId,
      sku: v.sku,
      option0: v.option0,
      priceAmount: v.priceAmount,
      priceCurrency: v.priceCurrency,
      priceFormatted: v.priceFormatted,
      hideDisplayPrice: details.hideDisplayPrice,
    };
  });

export const buildVariantsOptionsFallback = item =>
  item.variantInfo.map((i, index) => ({
    name: i.name,
    position: index + 1,
    values: [i.value],
  }));

export const createCarouselItemKey = item => `${item.id}_${item.localId}`;

export const isSameCarouselItem = (itemA, itemB) =>
  itemA.id === itemB.id && itemA.localId === itemB.localId;

export const rebuildVariantSelection = (name, value, selection, variants) => {
  const newVariants = { ...selection, [name]: value };
  const availableVariants = variants.filter(
    v => v.available && v.variantInfoMap[name] === value,
  );

  if (availableVariants.length) {
    const variantNames = Object.keys(newVariants);
    const availabilityByVariant = variantNames.reduce((result, n) => {
      result[n] = availableVariants.filter(
        v => v.variantInfoMap[n] === newVariants[n],
      );
      return result;
    }, {});
    const availabilityCounter = availableVariants.map(v =>
      variantNames.reduce((counter, n) => {
        if (availabilityByVariant[n].includes(v)) return counter + 1;
        return counter;
      }, 0),
    );
    const maxCounter = max(availabilityCounter);
    const maxVariantIndex = availabilityCounter.indexOf(maxCounter);
    const maxVariant = availableVariants[maxVariantIndex];

    return variantNames.reduce((result, n) => {
      const val = maxVariant.variantInfoMap[n];
      if (val) result[n] = val;
      return result;
    }, {});
  }

  return newVariants;
};

export const mapVariantInfo = (variantInfoArray, options) =>
  fromPairs(
    variantInfoArray.map((v, i) => [
      v.name,
      {
        optionId: v.name,
        optionName: v.name,
        optionPosition:
          v.position ??
          options?.find(opt => opt.name === v.name)?.position ??
          i,
        value: v.value,
        name: v.value,
      },
    ]),
  );

export const mapShipmentActionStatus = status => {
  return status?.error
    ? "error"
    : status?.loading
    ? "loading"
    : status?.data
    ? "success"
    : undefined;
};

export const newSelectedItemToState = item => {
  const parentId = item.reason.parent || item.reason.id;
  const childId = item.reason.parent ? item.reason.id : null;
  const parentReason = find({ id: parentId }, item.availableReasons);
  const childReason = find({ id: childId })(item.availableReasons);
  const isShopNow = item.type === "exchangeForCredit";
  return {
    localId: item.localId,
    id: item.itemId,
    sku: item._sku, // sku can be missing on nth's state, use the private attribute _sku instead.
    type: isShopNow ? "return" : item.type,
    isStoreCredit: isShopNow,
    eligible: !!parentReason?.eligible,
    ineligibleReason: parentReason?.ineligibleReason ?? [],
    returnReason: parentReason?.id,
    childReturnReason: childReason
      ? {
          eligible: !!childReason.eligible,
          ineligibleReason: childReason.ineligibleReason ?? [],
          returnReason: childReason.id,
        }
      : null,
    quantity: item.quantity,
    pictures: item.pictures ?? [],
    comment: item.customerComments,
    exchange:
      item.exchangeTo && !isShopNow
        ? {
            ...item.exchangeTo,
            sku: item.exchangeTo._sku, // sku can be missing on nth's state, use the private attribute _sku instead.
            variantInfo: Object.values(
              item.exchangeTo.variantInfo ?? {},
            ).map(v => ({ name: v.optionId, value: v.value })),
          }
        : undefined,
  };
};

export const eventDataToNewSelectedItem = value => {
  let { item, selectedReason, ...others } = value;
  return {
    ...item,
    ...others,
    reason: selectedReason,
  };
};

// TRICKS: an constant empty array to avoid irrelevant rerender. Without it, it
// cases the `useMemo` of components, and hooks (eg. use-item-state,
// use-item-selectors), who depends on ineligibleReasons, to rerender every
// time.
const EMPTY_ARRAY = [];
export const itemIneligibleReasons = item => {
  if (item.someReasonsEligible) {
    return EMPTY_ARRAY;
  }

  const matrixIntersection = ary =>
    reduce(intersection, ary && ary[0], ary) || EMPTY_ARRAY;
  const reasonsMatrix = item.eligibilityCriteria.map(ec => ec.ineligibleReason);
  return uniq(matrixIntersection(reasonsMatrix));
};

export const useNthAllItems = ({ localItems }) => {
  const orderFetchData = useOrderFetchData();
  const order = orderFetchData.state.data?.order;

  return useMemo(
    () =>
      localItems.map(item => {
        const sortByReasonPriority = sortBy(
          criteria =>
            orderFetchData?.state?.data?.returnReasonsById?.[
              criteria.returnReason
            ]?.priority,
        );
        let ret = compose(
          config.showItemSku ? identity : omit("sku"), // show SKU only if the feature flag is enabled
          merge({
            _sku: item.sku, // rename the sku attribute for internal use, because it can be removed when show_item_sku is disabled.
            price: item.hideDisplayPrice
              ? undefined
              : {
                  value:
                    item.priceAmount * order.presentmentCurrencySubunitToUnit,
                  currency: order.presentmentCurrency,
                },
            eligibilities: {
              return: {
                isEligible: item.allowReturn,
                message:
                  !item.allowReturn && !item.allowExchange
                    ? itemIneligibleReasons(item).join("<br/>")
                    : undefined,
              },
              exchange: {
                isEligible: item.allowExchange,
              },
              repair: {
                isEligible: false,
              },
            },
            availableReasons: compose(
              map(criteria => ({
                ...criteria,
                id: criteria.returnReason,
                name: criteria.returnReasonTitle,
                childIds: criteria.returnReasonParent
                  ? null
                  : compose(
                      map(c => c.returnReason),
                      sortByReasonPriority,
                      filter(
                        c => criteria.returnReason === c.returnReasonParent,
                      ),
                    )(item.eligibilityCriteria),
              })),
              sortByReasonPriority,
              filter(
                criteria =>
                  !criteria.returnReasonParent ||
                  item.eligibilityCriteria.some(
                    parent =>
                      parent.returnReason === criteria.returnReasonParent,
                  ),
              ),
            )(item.eligibilityCriteria),
            variantInfo: mapVariantInfo(item.variantInfo),
          }),
          renameKeys({
            id: "itemId",
            productTitle: "name",
            productImageUrl: "imageUrl",
            returnableQuantity: "remainingQuantity",
            variantInfo: "variantInfoOriginal",
          }),
        )(item);

        return ret;
      }),
    [orderFetchData, order, localItems],
  );
};

export const useNthExchangeProps = ({ selectingItem }) => {
  const orderFetchData = useOrderFetchData();
  const order = orderFetchData.state.data?.order;
  const state = useReturnStepsState();
  const [exchangeVariantInfo, setExchangeVariantInfo] = useState();

  const hideExchangeOptions =
    config.isReturnUpsellDisabled && state.selectingItem?.type === "return";
  const exchangeCurrency = order?.presentmentCurrency;
  const { result: exchangeResult, status } = useEvenExchangeProduct({
    query: selectingItem?.name ?? "",
    productId: selectingItem?.productId ?? "",
    variantId: selectingItem?.variantId ?? "",
    price: selectingItem?.priceAmount ?? 0,
    compareAtPrice: selectingItem?.compareAtPriceAmount,
    displayPrice: selectingItem?.displayPrice,
    currency: exchangeCurrency,
    locale,
    countryCode: order?.fromCountryCode,
    showProduct: true,
  });

  const exchangeVariants = useMemo(
    () =>
      exchangeResult && !hideExchangeOptions
        ? buildVariants(exchangeResult).map(({ sku, ...v }) => ({
            ...v,
            _sku: sku, // rename the sku attribute for internal use, because it can be removed when show_item_sku is disabled.
            price: {
              value: v.priceAmount * order.presentmentCurrencySubunitToUnit,
              currency: exchangeCurrency,
            },
            imageUrl: v.productImageUrl,
            variantInfo: mapVariantInfo(v.variantInfo, exchangeResult.options),
          }))
        : [],
    [exchangeResult, hideExchangeOptions],
  );
  const exchangeOptions = useMemo(() => {
    if (!exchangeResult?.options || hideExchangeOptions) return [];

    // setup sparsem availability check
    const sparsem = new Sparsem();
    const sparsemVariants = exchangeVariants
      .filter(v => v.available)
      .map(v => {
        let values = compose(
          infos => infos.map(info => info.value),
          // map(get("value")),
          sortBy("optionPosition"),
        )(Object.values(v.variantInfo || {}));
        return values;
      });
    const sparsemOptions = sortBy("position", exchangeResult.options).map(
      opt => opt.values,
    );
    sparsem.setArr(sparsemVariants, sparsemOptions);

    return exchangeResult.options.map(opt => {
      // When there are no available variants of a product (completely missing
      // in the array), we hide the option from the exchange swatches. It is
      // meaningless to show it on UI, because we don't know the product image,
      // there are no `availabilityStatus` we can show and no available variants
      // as well. We only filter this for "Product" option, because consumer may
      // still want to know other out of stock options (e.g. all sizes including
      // those were out of stock)
      const filterUnavailableOption = v =>
        exchangeResult.variantInfo.some(
          info => info[`option${opt.position}`] === v,
        );
      let values = opt.values
        .filter(opt.name === "Product" ? filterUnavailableOption : stubTrue)
        .map((v, i) => ({
          name: v,
          value: v,
          position: i,
        }));
      if (config.sortExchangeVariantsByAvailability) {
        // query the availability of different values under the current option and exchange selections
        const sparsemQuery = exchangeResult.options.map(o =>
          o.name === opt.name
            ? null
            : exchangeVariantInfo?.[o.name]?.value ?? null,
        );
        const sparsemResult = sparsem.search(sparsemQuery);
        const availability = values.reduce((acc, v) => {
          acc[v.value] =
            sparsemResult[v.value] === UNAVAILABLE_IN_THIS_SET ||
            sparsemResult[v.value] == UNAVAILABLE_IN_ANY_SET;
          return acc;
        }, {});
        values = compose(
          vs => vs.map((v, i) => set("position", i, v)),
          sortBy(v => availability[v.value]),
        )(values);
      }
      return {
        ...opt,
        id: opt.name,
        displayType: /^product$/i.test(opt.name) ? "image" : undefined,
        values,
      };
    });
  }, [
    hideExchangeOptions,
    config.sortExchangeVariantsByAvailability,
    exchangeResult,
    exchangeVariants,
    exchangeVariantInfo,
  ]);

  return memoObjectByKeyValues({
    exchangeVariants,
    exchangeOptions,
    status,
    setExchangeVariantInfo,
  });
};

export const useNthSubmittedReturns = ({ allItems }) => {
  const orderFetchData = useOrderFetchData();
  const order = orderFetchData.state.data?.order;
  const returnHistory = orderFetchData.state.data?.returnHistory;
  const loginSession = useLoginSession();
  const customer = useCustomer();
  const { cancelReturn, cancelStatus } = useCancelReturn();
  const {
    changeRefundMethod,
    status: changeRefundMethodStatus,
  } = useChangeRefundMethod();

  const [returnAction, setReturnAction] = useState();

  const returnActionStatus = useMemo(() => {
    const { returnId } = returnAction ?? {};
    if (!returnId) return;

    const reshopCancelError = getReshopCancelError(cancelStatus.error);
    const reshopCancelErrorMessage =
      reshopCancelError &&
      interpolate(
        config.translations.choose_items_rma_action_reshop_cancel_error,
        { refund_id: reshopCancelError.reshop_refund_id },
      );

    if (returnAction.action === "cancel") {
      const status = mapShipmentActionStatus(cancelStatus);
      const message =
        status === "error"
          ? reshopCancelError
            ? reshopCancelErrorMessage
            : config.translations.choose_items_rma_action_cancel_error
          : undefined;

      return { returnId, status, message };
    }
    if (returnAction.action === "changeRefundMethod") {
      const status = mapShipmentActionStatus(changeRefundMethodStatus);
      const message = reshopCancelError
        ? reshopCancelErrorMessage
        : changeRefundMethodStatus.error?.message;
      return { returnId, status, message };
    }
  }, [returnAction, cancelStatus, changeRefundMethodStatus]);

  const submittedReturns = useMemo(
    () =>
      returnHistory.map(history => {
        const convertLineItem = item =>
          compose(
            set("itemId", `gid://shopify/LineItem/${item.itemId}`),
            merge(
              allItems.find(
                localItem =>
                  localItem.itemId === `gid://shopify/LineItem/${item.itemId}`,
              ) ?? {},
            ),
            merge({
              reason: {
                id: item.childReturnReasonCode ?? item.returnReasonCode,
                name: item.returnReason,
              },
            }),
            renameKeys({
              itemName: "name",
            }),
          )(item);

        // Since we will split exchange line item to multiple return line items,
        // we need to group it back.
        const groupSplittedLineItem = items => {
          const ret = compose(
            map(itemsWithSameId => ({
              ...itemsWithSameId[0],
              quantity: sumBy("quantity", itemsWithSameId),
            })),
            groupBy("itemId"),
          )(items);
          return ret;
        };

        const returnLabels = [];
        const returnShipmentActions = [];

        if (history.returnLabel) {
          returnLabels.push({
            dataUrl: null,
            labelType: "PACKING_SLIP_AND_SHIPPING_LABEL",
            labelUrl: history.returnLabel,
            mimeType: "application/pdf",
          });
          returnShipmentActions.push({
            title: getTranslation("choose_items_rma_action_reprint_labels"),
            href: history.returnLabel,
          });
        }

        if (history.qrCodeUrl) {
          returnLabels.push({
            dataUrl: null,
            labelType: "QR_CODE",
            labelUrl: history.qrCodeUrl,
            mimeType: "image/png",
          });
          returnShipmentActions.push({
            title: getTranslation("choose_items_rma_action_reprint_qr_code"),
            href: history.qrCodeUrl,
          });
        }

        const isMultiLabel = history.returnShipments.length > 1;
        history.returnShipments?.forEach(ship => {
          if (ship.returnTrackingLink) {
            returnShipmentActions.push({
              title: isMultiLabel
                ? interpolate(
                    getTranslation(
                      "choose_items_rma_action_track_return_multi_label",
                    ),
                    {
                      letter: ship.letter,
                      tracking_number: ship.returnTrackingNumber,
                    },
                  )
                : getTranslation("choose_items_rma_action_track_return"),
              href: ship.returnTrackingLink,
            });
          }
        });

        if (
          history.refundMethod === "reshop" &&
          [
            "initiated",
            "on_its_way_to_retailer",
            "delivered_to_retailer",
            "received_by_retailer",
          ].includes(history.currentStatus)
        ) {
          returnShipmentActions.push({
            title: getTranslation(
              "choose_items_rma_action_change_refund_method_original_payment",
            ),
            onClick: () => {
              setReturnAction({
                returnId: history.id,
                action: "changeRefundMethod",
              });
              changeRefundMethod({
                id: history.id,
                refundMethod: "original_payment",
                note: "",
              })
                .then(result => {
                  loginSession.logout();

                  if (config.isNthLoginUi) {
                    toggleContainerClass("nvo_container--new-login");
                    toggleContainerClass("nvo_container--logged-in");
                  }
                })
                .catch(error => {
                  console.error("error", error);
                });
            },
            status:
              returnAction?.returnId === history.id &&
              returnAction?.action === "changeRefundMethod"
                ? returnActionStatus
                : undefined,
          });
        }

        if (history.cancellable) {
          returnShipmentActions.push({
            title: getTranslation("choose_items_rma_action_cancel_return"),
            onClick: () => {
              setReturnAction({
                returnId: history.id,
                action: "cancel",
              });
              cancelReturn({
                orderNumber: order.orderNumber,
                email: customer.email,
                returnId: history.id,
              })
                .then(result => {
                  loginSession.logout();

                  if (config.isNthLoginUi) {
                    toggleContainerClass("nvo_container--new-login");
                    toggleContainerClass("nvo_container--logged-in");
                  }
                })
                .catch(error => {
                  console.error("error", error);
                });
            },
            status:
              returnAction?.returnId === history.id &&
              returnAction?.action === "cancel"
                ? returnActionStatus
                : undefined,
          });
        }

        const ret = compose(
          merge({
            displayStatus: getTranslation(
              `return_status_simplified_to_consumer_${simplifyReturnStatus(
                history.currentStatus,
              )}`,
            ),
            canBeCancelled: history.cancellable,
            returnShipments: [
              {
                returnLabels,
                items: groupSplittedLineItem(
                  history.returnItems.map(convertLineItem),
                ),
                returnShipmentActions,
                returnTracking: history.carrierTrackingNumber
                  ? {
                      carrierMoniker: history.returnMethod,
                      carrierName: history.returnMethod,
                      narvarTrackingUrl: `?rid=${history.id}&tracking_number=${history.carrierTrackingNumber}`,
                      trackingNumber: history.carrierTrackingNumber,
                    }
                  : undefined,
              },
            ],
          }),
          renameKeys({
            currentStatus: "status",
            returnShipments: "returnShipmentsRaw",
          }),
        )(history);

        return ret;
      }),
    [returnHistory, allItems, returnAction, returnActionStatus],
  );

  return memoObjectByKeyValues({
    submittedReturns,
    returnActionStatus,
    cancelStatus,
  });
};
