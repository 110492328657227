import { useMemo } from "react";
import {
  useOrderFetchData,
  useReturnStepsState,
} from "../contexts/returnSteps";

const useCustomer = () => {
  const state = useReturnStepsState();
  const fetchData = useOrderFetchData();
  const order = fetchData.state.data?.order;
  const displayEmail = state.guestReturn
    ? state.decodedGuestEmail
    : state.email;
  const customer = useMemo(
    () =>
      order
        ? {
            firstName: order.fromFirstName,
            lastName: order.fromLastName,
            fullName: `${order.fromFirstName} ${order.fromLastName}`,
            email: displayEmail,
          }
        : undefined,
    [order, displayEmail],
  );

  return customer;
};

export default useCustomer;
