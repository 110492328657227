import { useMemo, useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  compose,
  filter,
  groupBy,
  mapValues,
  pick,
  set,
  map,
  first,
  pickBy,
} from "lodash/fp";
import { config } from "../config";
import errorNotifier from "../../shared/modules/error-notifier";

export const EVEN_EXCHANGE_PRODUCT = gql`
  query EvenExchangeProduct(
    $query: String!
    $productId: String!
    $variantId: String
    $compareAtPrice: Float
    $price: Float!
    $currency: String!
    $locale: String
    $countryCode: String
    $showProduct: Boolean
  ) {
    evenExchangeProduct(
      query: $query
      productId: $productId
      variantId: $variantId
      compareAtPrice: $compareAtPrice
      price: $price
      currency: $currency
      locale: $locale
      countryCode: $countryCode
      showProduct: $showProduct
    )
      @connection(
        key: "evenExchangeProduct"
        filter: ["query", "productId", "variantId", "price"]
      ) {
      id
      name
      productImageUrl
      productImageAltTxt
      options {
        name
        position
        values
      }
      variantInfo {
        productId
        variantId
        displayName
        sku
        imageSrc
        imageAlt
        availableForSale
        availabilityStatus
        option0
        option1
        option2
        option3
        priceAmount
        priceCurrency
        priceFormatted
      }
    }
  }
`;

const useEvenExchangeProduct = ({ displayPrice, ...variables }) => {
  const { query, productId, variantId, price } = variables;
  const skip = !query || !productId || !variantId || typeof price !== "number"; // skip gql query when required fields are not provided
  const { called, data, error, loading } = useQuery(EVEN_EXCHANGE_PRODUCT, {
    skip,
    variables,
  });

  const result = useMemo(() => {
    if (!data || !data.evenExchangeProduct) return;

    const { evenExchangeProduct } = data;
    let variantInfo = evenExchangeProduct.variantInfo;
    let options = evenExchangeProduct.options ?? [];

    if (
      config.isShopNowWithCreditEnabled &&
      variantInfo.length === 1 &&
      !options.length
    ) {
      const name = variantInfo[0].displayName;
      variantInfo = variantInfo.map(set("option0", name));
      options = [
        {
          name: "Product",
          position: 0,
          values: [name],
        },
      ];
    }

    // ignore the latest price when it is an available option of even exchange,
    // show the original item price instead
    if (!config.isUnevenExchangesEnabled) {
      variantInfo = variantInfo.map(v => ({
        ...v,
        priceAmount: price,
        priceFormatted: displayPrice,
      }));
    }

    // [SHOPZ-2423] for each pair of options, if there are options collisions
    // between variants (two variants have the same size and color), we should
    // pick the first available variant and log it in rollbar, because it can be
    // a store setting issue or ProductSearchPostprocessor bug.
    const getVariantOptionsKey = v =>
      Object.values(pick(["option0", "option1", "option2", "option3"], v)).join(
        "-",
      );
    const groupedVariantInfo = compose(
      mapValues(variants => {
        const available = filter(["availableForSale", true], variants);
        return available.length > 0 ? available : variants;
      }),
      groupBy(getVariantOptionsKey),
    )(variantInfo);
    const someVariantsCollided = Object.values(groupedVariantInfo).some(
      v => v.length > 1,
    );
    const variantsCollied = compose(
      mapValues(map(pick(["displayName", "productId", "variantId", "sku"]))),
      pickBy(variants => variants.length > 1),
    )(groupedVariantInfo);

    variantInfo = compose(map(first), Object.values)(groupedVariantInfo);
    const someAvailable = variantInfo.some(v => v.availableForSale);

    // always show the original product item as first option
    if (options[0]?.name === "Product") {
      const sortedValues = [...options[0].values].sort((a, b) =>
        a === query ? -1 : b === query ? 1 : 0,
      );
      options = set([0, "values"], sortedValues, options);
    }

    return {
      ...evenExchangeProduct,
      options,
      variantInfo,
      someAvailable,
      someVariantsCollided,
      variantsCollied,
    };
  }, [data, query, price, displayPrice]);

  useEffect(() => {
    if (result?.someVariantsCollided) {
      console.warn(
        "Options collision found in exchange variants. It can be caused by incorrect store settings or a bug in ProductSearchPostprocessor",
      );
      errorNotifier.warn(
        "exchanges: options collision found in exchange variants",
        { item: variables, variantsCollied: result.variantsCollied },
      );
    }
  }, [result?.someVariantsCollided]);

  const status = useMemo(
    () => ({
      called,
      error,
      loading,
    }),
    [called, error, loading],
  );

  return useMemo(
    () => ({
      result,
      status,
    }),
    [result, status],
  );
};

export default useEvenExchangeProduct;
